<button mat-icon-button type="button" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>

<ng-template [ngIf]="view=='form'">
	<h2 mat-dialog-title>Please Contact me about this community.</h2>
	<p class="error">{{ form_error }}</p>
	<mat-dialog-content>
		<form name="community-request-info-contact" (submit)="submit()" [formGroup]="request_form">
			<mat-form-field class="form-input">
				<input formControlName="name" type="text" name="name" matInput required>
				<mat-placeholder>Name</mat-placeholder>
				<mat-error>{{ get_error('name') }}</mat-error>
			</mat-form-field>
			<mat-form-field class="form-input">
				<input formControlName="email" type="text" name="email" matInput required>
				<mat-placeholder>Email</mat-placeholder>
				<mat-error>{{ get_error('email') }}</mat-error>
			</mat-form-field>
			<mat-form-field class="form-input">
				<input formControlName="phone" type="text" name="phone" matInput>
				<mat-placeholder>Phone</mat-placeholder>
				<mat-error>{{ get_error('phone') }}</mat-error>
			</mat-form-field>
			<mat-form-field class="form-input">
				<textarea formControlName="message" matInput></textarea>
				<mat-placeholder>Optional Message</mat-placeholder>
			</mat-form-field>
			<button mat-raised-button type="submit">Contact Me</button>
		</form>
	</mat-dialog-content>
</ng-template>
<ng-template [ngIf]="view=='spinner'">
	<mat-dialog-content>
		<mat-progress-bar mode="indeterminate"></mat-progress-bar>
	</mat-dialog-content>
</ng-template>
<ng-template [ngIf]="view=='success'">
	<h2 mat-dialog-title><mat-icon>check_circle</mat-icon> You're info was sent!</h2>
</ng-template>
