<div id="app-theme" class="theme page-{{page}}">
    <mat-sidenav-container fullscreen #sidenavContainer *ngIf="s.ready">
        <mat-toolbar id="app-toolbar" class="main-header" [class.searching]="searching">

            <mat-toolbar-row class="hdr-row hdr-row-menu">

                <button class="hdr-btn-nav" mat-icon-button (click)="click_go_back()">
					<mat-icon matTooltip="Menu" matTooltipPosition="right" class="layout-icon" color="primary" >{{toolbaricon}}</mat-icon>
					<span matTooltip="Back" matTooltipPosition="right" *ngIf="!onSearchPage()" class="txt-back">Back</span>
				</button>

                <span *ngIf="!searching" class="layout-spacer"></span>

                <a class="logo" href="https://seniorcommunities.guide">
                    <img class="logo-desk" [src]="'/assets/logo.png'">
                    <img class="logo-mob" [src]="'/assets/logo-icon.png'">
                </a>

            </mat-toolbar-row>

            <!-- <span class="layout-spacer"></span> -->

            <mat-toolbar-row class="hdr-row hdr-row-search">

                <div class="wrapper-search">

                    <button class="hdr-btn-search search-button" matTooltip="Search" matTooltipPosition="left" mat-icon-button (click)="searchButton()">
						<mat-icon class="layout-icon" color="primary" >search</mat-icon>
					</button>

                    <form class="hdr-input-search" (submit)="run_search()">
                        <mat-form-field class="search-form" [hidden]="!searching" [class.hidden]="!searching" floatLabel="always" appearance="standard">

                            <mat-label></mat-label>
                            <input class="search-input" matInput #mapsearch (input)="search_change($event.target.value)" (blur)="searching = true" (change)="run_search()" placeholder="Zip Code or City">

                            <!-- <mat-hint align="start">
								<strong>Set Map Location</strong>
							</mat-hint> -->

                        </mat-form-field>
                    </form>

                </div>

                <button class="hdr-btn-filters" mat-button (click)="open_filter()">
					<!-- <mat-icon class="layout-icon" color="primary">filter_list</mat-icon> -->
					{{ui.filterButtonText()}}
				</button>

            </mat-toolbar-row>

        </mat-toolbar>

        <mat-sidenav #sidenav id="app-sidenav" mode="over" class="layout-sidenav">

            <mat-toolbar class="nav-header">
                <span>Menu</span>
                <span class="layout-spacer"></span>
                <button matTooltip="Close" matTooltipPosition="right" mat-icon-button (click)="sidenav.close()">
					<mat-icon class="layout-icon">close</mat-icon>
				</button>
            </mat-toolbar>
            <mat-list>
                <mat-list-item>
                    <a href="https://seniorcommunities.guide" target="_blank">
                        <button mat-button>
							<mat-icon>home</mat-icon>
							<span>SeniorCommunities.Guide</span>
						</button>
                    </a>
                </mat-list-item>
                <mat-list-item *ngFor="let item of nav">
                    <button mat-button (click)="change_page(item.link)">
						<mat-icon *ngIf="item.icon!=''">{{item.icon}}</mat-icon>
						<span>{{item.label}}</span>
					</button>
                </mat-list-item>
                <mat-list-item *ngIf="loggedin()">
                    <button mat-button (click)="change_page('account')">
						<mat-icon>account_box</mat-icon>
						<span>Account</span>
					</button>
                </mat-list-item>
                <!-- <mat-list-item *ngIf="!loggedin()">
					<button mat-button (click)="access_dialog()">
						<mat-icon>account_box</mat-icon>
						<span>Sign In</span>
					</button>
				</mat-list-item> -->
                <mat-list-item>
                    <a href="https://seniorcommunities.guide/contact/" target="_blank">
                        <button mat-button>
							<mat-icon>account_box</mat-icon>
							<span>Send Feedback</span>
						</button>
                    </a>
                </mat-list-item>
                <mat-list-item>
                    <a class="txt-highlight" href="https://sales.seniorcommunities.guide" target="_blank">
                        <button mat-button>
							<mat-icon>group</mat-icon>
							<span>Become a Partner</span>
						</button>
                    </a>
                </mat-list-item>
            </mat-list>
        </mat-sidenav>
        <mat-sidenav #filternav position="end" mode="over" class="layout-filternav">
            <!-- <mat-list>
				<form class="search">

					<span class="layout-spacer"></span>
					<button class="sidenav-close-button" mat-icon-button (click)="closefilters()">
						<mat-icon class="layout-icon">chevron_right</mat-icon>
					</button>
                    <mat-form-field class="search-form"
                        [floatLabel]="auto"
						floatLabel="never">
                            <input class="search-input" matInput #mapsearch (input)="search_change($event.target.value)" 
                            placeholder="Set Map Location by Zip Code, City, or State">
					</mat-form-field>
				</form>
			</mat-list> -->

            <mat-toolbar class="slide-header">
                <mat-toolbar-row>
                    <span>Filters</span>
                    <span class="layout-spacer"></span>
                    <button class="sidenav-close-button" matTooltip="Close Filters" matTooltipPosition="left" mat-icon-button (click)="closefilters()">
                        <mat-icon class="layout-icon">chevron_right</mat-icon>
                    </button>
                </mat-toolbar-row>
                <mat-toolbar-row class="filter-button-row">
                    <button mat-button (click)="clearFilters()">Clear Filters</button>
                    <span class="layout-spacer"></span>
                    <button mat-button (click)="closefilters()">Apply</button>
                </mat-toolbar-row>
            </mat-toolbar>
            <main class="content">
                <mat-list>
                    <app-filter>filter</app-filter>
                </mat-list>
            </main>
            <footer class="filters-bottom-toolbar">
                <mat-toolbar>
                    <button mat-raised-button (click)="closefilters()">Apply</button>
                </mat-toolbar>
            </footer>

        </mat-sidenav>

        <div #appcontent id="app-content" class="app-content layout-sidenav-content">
            <router-outlet></router-outlet>
        </div>

    </mat-sidenav-container>
    <div *ngIf="!s.ready">
        <mat-progress-spinner mode="indeterminate" color="primary" class="loading-spinner"></mat-progress-spinner>
    </div>
</div>