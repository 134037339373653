import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { StorageService } 	from '../service/storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilterService } from '../service/filter.service';
import { filter } from 'rxjs/operators';


declare var jQuery: any;

@Injectable()
export class UiService {
	public element		: any;
	public property 	: any;

	public default_parent_page	: string = '/';
	public parent_page			: string = this.default_parent_page;
	public mobile_width			: number = 991;
	public maptoggleicon		: string;
	public maptoggletext		: string;
	public mapstate				: boolean;
	public mobile				: boolean;
	size						: any;
	constructor(
		public _location: Location,
		public router: Router,
		public store : StorageService,
		public messagepop: MatSnackBar,
		private fs : FilterService
	) {
		this.element 		= {};
		this.property 		= {};
		this.maptoggleicon 	= "expand_less";
		this.maptoggletext 	= "Show Results";
		this.mapstate 		= true;
		this.ismobile();
		window.addEventListener("resize",() => {
			this.resize();
		});
		window.addEventListener("focus",() => {
			this.resize();
		});
		setTimeout(()=>{
			this.resize();
			this.open_map();
		},0);

	}
    message(message : string = null, action : string = 'Ok', duration : number = 0) {
        let config : any = {};
        if (duration > 0) {
            config.duration = duration;
        }
        return this.messagepop.open(message, action, config);
    }
	set(name,element)
		{ this.element[name] = element; }
	get(name)
	{
		if(!this.element[name])
			{ return null; }
		return this.element[name];
	}
	
	ismobile() : boolean
		{ this.mobile = window.innerWidth <= this.mobile_width; return this.mobile; }
	
	elsize(selector,width:number=null,height:number=null)
	{
		let e = jQuery(selector);
		if(e.length<1)
			{ return {}; }
		if(width)
			{ e.width(width); }
		if(height)
			{ e.height(height); }
		return {
			w: e.outerWidth(),
			h: e.outerHeight()
		};
	}
	set_parent_path(path)
	{
		if (!path)
			{ return; }

		this.parent_page = path;
	}

	set_favorite(community)
	{

	}

	backClicked() {
		this.router.navigateByUrl(this.parent_page||'/');
		this.parent_page = this.default_parent_page;
	}

	format_price(p)
	{
		return "$"+(parseFloat(p).toLocaleString()) + " + per month";
	}

	filterButtonText()
	{
		let filters = this.fs.filters_count();
		if(filters>0) { 
			// add class to button with class of 'hdr-btn-filters'
			jQuery('.hdr-btn-filters').addClass('hdr-btn-filters-filtered');
		} else {
			jQuery('.hdr-btn-filters').removeClass('hdr-btn-filters-filtered');
		}
		// return filters>0 ? filters +' Filter'+(filters==1?'':'s') : 'Add Filters';
		return filters>0 ? 'Filters (' + filters + ')' : 'Add Filters';
	}

	priceRange(c)
	{
		let p = c.budget_range;
		let r = "";
		if (this.hasPrice(c)) {
			p[0]
			r += "$"+(parseFloat(p[0]).toLocaleString());
			if(p[1]){
				// r += " - $"+(parseFloat(p[1]).toLocaleString()) + " + per month";
				r += " - $"+(parseFloat(p[1]).toLocaleString()) + " +";
			}else{
				// r += " + per month";
				r += " +";
			}
		}
		return r;
	}

	hasPrice(c){
		let p = c.budget;
		return p.length > 0 && p[0] > 0;

	}
	position_contacts()
	{
		var toolbar_height		= +jQuery("mat-toolbar.main-header").outerHeight()||0;
		var scroll_root 		= jQuery(".desktop .mat-drawer-content");
		var contacts 			= jQuery("#contactcard");
		var prof_hdr_height 	= jQuery(".desktop .profile-community .header-card").outerHeight()||0;
		var prof_content_height	= jQuery(".desktop .profile-community .prof-col-details").outerHeight()||0;




		var cpos				= contacts.offset() || 0;
		if(cpos)
		{
			var update = function(){
				let mobile			= this.ismobile();
				let margin			= 30;
				let scrollpos 		= scroll_root.scrollTop() + toolbar_height + margin;
				let offset 			= scrollpos - cpos.top;

				if(!mobile && offset>0)
				{
					let par = contacts.parent();
					contacts.css({
						"display"	: "block",
						"position"	: "sticky",
						"z-index"	: "1",
						"top"		: (toolbar_height + margin)+"px",
						"left"		: par.offset().left+"px",
						"width"		: par.width()+"px"
					});

					par.css({
						"height"	: (prof_content_height + prof_hdr_height)+"px"
					});

				}
				else
				{
					let par = contacts.parent();
					contacts.css({"position":"static","z-index":"auto","top":"auto","left":"auto","width":"100%"});
					par.css({"height":"auto"});
				}
			};
			scroll_root.bind("scroll resize",() => { update.call(this) });
			jQuery(window).bind("resize",() => { update.call(this) });
		}
	}
	mapLink(community){

		let maplink = "";
		let data = [
			encodeURI(community.name),
			encodeURI(community.address.street),
			encodeURI(community.address.city),
			encodeURI(community.address.state)
		];
		maplink += "https://www.google.com/maps/search/" + data.join("+");
		return maplink;
	}

	mapDirLink(community){

		let mapDirlink = "";
		let data = [
			encodeURI(community.name),
			encodeURI(community.address.street),
			encodeURI(community.address.city),
			encodeURI(community.address.state)
		];
		mapDirlink += "https://www.google.com/maps/dir/?api=1&destination=" + data.join("+");
		return mapDirlink;
	}	

	update_map_icon(){
		if(this.mapstate){
			this.maptoggleicon = "expand_less";
		}else{
			this.maptoggleicon = "expand_more";
		}
	}

	update_map_text(){
		if(this.mapstate){
			this.maptoggletext = "Show Results";
		}else{
			this.maptoggletext = "Show Map";
		}
	}

	toggle_map(){
		if(this.mapstate){
			this.close_map();
		}else{
			this.open_map();
		}
		return this.mapstate;
	}

	open_map(){
		this.mapstate = true;
		this.update_map_icon();
		this.update_map_text();
		this.resize();
		return this.mapstate;
	}

	close_map(){
		this.mapstate = false;
		this.update_map_icon();
		this.update_map_text();
		this.resize();
		return this.mapstate;
	}
	resize()
	{
		this.size		= {};
		let root		= jQuery("app-search,#app-theme").children().add("#app-content");
		let topmargin 	= jQuery("#app-content");
		let mobile		= this.ismobile();

		this.size.window		= this.elsize(window);
		this.size.properties	= this.elsize("#properties");
		this.size.toolbar 		= this.elsize("#app-toolbar");
		this.size.mapbutton 	= this.elsize("#togglemapbutton");
		this.size.filter		= this.elsize("#filter-options");
		
		let vheightdiff 		= 0;
		let state 				= this.mapstate;


		if(this.size.toolbar && this.size.toolbar.h)
			{ vheightdiff += this.size.toolbar.h; }

		// make sure the map is set to open if not mobile
		if(!mobile)
		{
			this.mapstate	= true;
			vheightdiff += 3;
		}
		else
		{
			if(mobile && this.size.filter && this.size.filter.h)
				{ vheightdiff += this.size.filter.h; }
			if(this.size.mapbutton && this.size.mapbutton.h)
				{ vheightdiff += this.size.mapbutton.h; }
		}
		let aheight = this.size.window.h-vheightdiff;
		if(!this.mapstate)
		{
			aheight = 0;
		}
		this.size.agmarea	= this.elsize("#agmmaparea",null,aheight);
		this.size.stgmap	= this.elsize("#stgmap",null,aheight);
		if(mobile)
			{ root.removeClass("desktop").addClass("mobile"); }
		else
			{ root.removeClass("mobile").addClass("desktop"); }

		root.removeClass("map_open");
		topmargin.removeAttr("style");

		if(jQuery("#stgmap").length==0)
		{
			root.addClass("nomap").removeClass("hasmap");
		}
		else
		{

			jQuery("#agmmaparea").css({"top":this.size.toolbar.h+"px"});
			if(this.mapstate)
			{
				root.addClass("map_open");
				if(mobile)
				{
					topmargin.css({"margin-top":this.size.toolbar.h+"px"});
					jQuery("#togglemapbutton").css({"top":"auto"});
				}
			}
			else
			{
				if(mobile)
				{
					jQuery("#togglemapbutton").css({"top":this.size.toolbar.h+"px"});
					topmargin.css({"margin-top":((this.size.mapbutton.h||0) + this.size.toolbar.h)+"px"});
				}

			}

			root.addClass("hasmap").removeClass("nomap");
			if(state!=this.mapstate)
				{ this.resize_loop(); }
		}
	}
	resize_loop(i=3,delay=500,c=function(){})
	{
		if(i>0)
		{
			i --;
			this.resize();
			setTimeout(()=>{
				this.resize_loop(i,delay);
			},delay);
		}
		else
		{
			c();
		}
	}
}
