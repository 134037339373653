<mat-toolbar class="sub-toolbar">
  Settings
</mat-toolbar>

<div class="settings-container">
	<mat-list-item *ngFor="let name of s.keys(s.settings)" [ngSwitch]="s.settings[name].type">
		<mat-form-field *ngSwitchCase="'select'">
			<mat-select 
				[placeholder]="s.settings[name].label" 
				[ngModel]="s.settings[name].value" 
				(ngModelChange)="s.changed($event,name)">
				<mat-option 
					*ngFor="let value of s.keys(s.settings[name].options)" 
					[value]="value">
						{{s.settings[name].options[value]}}
				</mat-option>
			</mat-select>
		</mat-form-field>
		<ng-container *ngSwitchCase="'button'">
			<button mat-raised-button color="accent"
				(click)="s.button_clicked($event,name)">
					{{s.settings[name].label}}
			</button>
		</ng-container>
	</mat-list-item>
</div>
