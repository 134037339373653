<div class="tour-container" [ngClass]="hidden ? 'hidden' : ''">
	<div class="tour-overlay" #overlay (click)="hide()"></div>
	<div class="tour-highlighter" #highlighter></div>
	<div class="tour-dialog-wrapper" [ngClass]="dialog_class" [hidden]="loading">
		<mat-card class="tour-dialog">
			<div class="tour-dialog-inner-wrapper">
				<div class="tour-close-wrapper">
					<button mat-mini-fab type="button" (click)="hide()"><mat-icon>close</mat-icon></button>
				</div>
				<mat-card-header>
					<mat-card-title><h2>{{ title }}</h2></mat-card-title>
				</mat-card-header>
				<mat-card-content class="tour-content">
					<p>{{ message }}</p>
				</mat-card-content>
			</div>
			<div class="tour-actions">
				<button mat-button type="button" (click)="prev()" *ngIf="module.current_step > 0">Previous</button>
				<button mat-button type="button" (click)="next()" *ngIf="module.current_step < module.steps.length-1">Next</button>
				<button mat-button type="button" (click)="hide()" *ngIf="module.current_step >= module.steps.length-1">Finish</button>
			</div>
		</mat-card>
	</div>
</div>
