<ng-template [ngIf]="view=='sign-in'">
	<h2 mat-dialog-title>Sign In</h2>
	<div mat-dialog-content>
	    <div class="third-parties" style="display: none;">
	        <button type="button" #googleSignin>
	            <img src="/assets/google/1x/btn_google_signin_dark_normal_web.png"/>
	        </button>
	    </div>
	    <!-- <mat-divider></mat-divider> -->
	    <form (submit)="login_submit(email, password)">
	        <mat-form-field>
	            <mat-icon matPrefix>email</mat-icon>
	            <input type="text" name="email" [(ngModel)]="email" matInput required>
	            <mat-placeholder>Email</mat-placeholder>
	        </mat-form-field>
	        <mat-form-field>
	            <mat-icon matPrefix>lock</mat-icon>
	            <input type="password" name="password" [(ngModel)]="password" matInput required>
	            <mat-placeholder>Password</mat-placeholder>
	        </mat-form-field>
	        <button mat-raised-button type="submit">Sign In</button>
	    </form>
	</div>
</ng-template>
<ng-template [ngIf]="view=='sign-up'">
	<h2 mat-dialog-title>Sign Up</h2>
	<div mat-dialog-content>
	    <form (submit)="login_submit(email, password, confirm)">
	        <mat-form-field>
	            <mat-icon matPrefix>email</mat-icon>
	            <input type="text" name="email" [(ngModel)]="email" matInput required>
	            <mat-placeholder>Email</mat-placeholder>
	        </mat-form-field>
	        <mat-form-field>
	            <mat-icon matPrefix>lock</mat-icon>
	            <input type="password" name="password" [(ngModel)]="password" matInput required>
	            <mat-placeholder>Password</mat-placeholder>
	        </mat-form-field>
	        <mat-form-field>
	            <mat-icon matPrefix>lock</mat-icon>
	            <input type="password" name="confirm" [(ngModel)]="confirm" matInput required>
	            <mat-placeholder>Confirm Password</mat-placeholder>
	        </mat-form-field>
	        <button mat-raised-button type="submit">Sign Up</button>
	    </form>
	</div>
</ng-template>
<ng-template [ngIf]="view=='spinner'">
    <mat-spinner></mat-spinner>
</ng-template>
