import { Component, OnInit } 					from '@angular/core';
import { Router } 								from '@angular/router';
import { StorageService } 						from '../../service/storage.service';
import { UiService } 							from '../../service/ui.service';
import { UserdataService } 						from '../../service/userdata.service';
import { ApiService } 							from '../../service/api.service';
import { FilterService } 						from '../../service/filter.service';


@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {
	favorites : any;
	communities : any;
	constructor(
		public store : StorageService,
		public ui : UiService,
		public api : ApiService,
		public userdata : UserdataService,
		private router: Router
	) { }

	ngOnInit()
	{
		this.favorites = this.userdata.favorites();
		this.communities = {};
		this.api.get_community_info(this.favorites).subscribe((communities) => {
			if(communities && !communities.error)
			{
				this.communities = communities;
			}
		});


		
	}

	showCommunity(id){
		this.router.navigate(['/community/'+id]);
	}
}
