<h2 mat-dialog-title>Sort Members by</h2>
<mat-form-field>
<mat-select 
	[ngModel]="s.settings.sort.value" 
	(ngModelChange)="setting_change($event,'sort')">
	<mat-option *ngFor="let value of s.keys(s.settings.sort.options)" [value]="value" >
	{{s.settings.sort.options[value]}}
	</mat-option>
</mat-select>
</mat-form-field>
<mat-dialog-actions>
	<button mat-button mat-dialog-close>Done</button>
	<!-- Can optionally provide a result for the closing dialog. -->
	<!-- <button mat-button [mat-dialog-close]="true">Sort</button> -->
</mat-dialog-actions>