import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {

	public community: any;
	public phone_link: string;
	constructor(
		public dialogRef: MatDialogRef<PhoneComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	)
	{
		this.community = data.community;
		this.phone_link = 'tel:'+this.community.phone.replace(/[^0-9]/g, '');
	}

	ngOnInit() {
	}

}
