import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// toggle dev_mode
declare var window: any;
var search = location.search.substr(1).split('&');
var params: any = {};
for (var i in search)
{
	var parts: any = search[i].split('=');
	params[parts[0]] = (isNaN(parts[1]) ? parts[1] : parseInt(parts[1]));
}

window.dev_mode = (typeof params.dev_mode !== 'undefined' ? (params.dev_mode !== 0) : window.dev_mode);


platformBrowserDynamic().bootstrapModule(AppModule);
