import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';

declare var window: any;
declare var gtag: any;

@Injectable()
export class AnalyticsService {

	public tracking_id: string = 'UA-111827983-1';

	// Define Google Analytics custom metrics here...
	// See this for help:  https://developers.google.com/analytics/devguides/collection/gtagjs/custom-dims-mets
	public custom_map = {
		'dimension1'	: 'location_id',
		'dimension2'	: 'match_type',
		'dimension3'	: 'match_value',
		'dimension4'	: 'autocomplete_source'
	};

	constructor(private s: SettingsService)
	{
		window.dataLayer = window.dataLayer || [];
		window.gtag = function() {
			window.dataLayer.push(arguments);
		};

		// s.get_config().subscribe(config => {
        //
		// 	if (!config.api_keys.google_analytics)
		// 		{ return; }
		// 	this.tracking_id = config.api_keys.google_analytics;
        //
		// 	var script = document.createElement('script');
		// 	script.type = 'text/javascript';
		// 	script.src = 'https://www.googletagmanager.com/gtag/js?id='+this.tracking_id;
		// 	script.async = true;
		// 	document.body.appendChild(script);
        //
		// 	this.init(this.tracking_id);
		// });

		var script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = 'https://www.googletagmanager.com/gtag/js?id='+this.tracking_id;
		script.async = true;
		document.body.appendChild(script);

		this.init(this.tracking_id);
	}

	init(tracking_id)
	{
		gtag('js', new Date());
		gtag('config', tracking_id, {
			'custom_map': this.custom_map
		});
	}

	page_view(page)
	{
		// gtag('config', 'page_view', {
		// 	'send_to': this.tracking_id
		// });

		gtag('config', this.tracking_id, {
			page_path: location.pathname,
			page_location: location.origin,
			page_title: document.title
		});
	}

	register_user(id)
	{
		gtag('config', this.tracking_id, {
		  'user_id': id
		});
	}

	user_login(method)
	{
		gtag('event', 'login', {
			method: method
		});
	}

	event(event_name: string, params: {})
	{
		gtag('event', event_name, params);
	}

}
