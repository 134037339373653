/****  USAGE  ****

TourModule.start([
	{
		// Scripts to run on entering a new step
		"onstep"		: [
			"$('.search-button').filter(':visible').first().click();"
		],
		// top, bottom, left, right, top-left, top-right, bottom-left, bottom-right, center (default)
		"dialog_position"	: "top",
		"title"				: "Some Information",
		"message"			: "This is where you put more context.",
		// Highlights first element that matches selector
		"selector"			: ".some-css-selector",
		// Add padding to highlighter (pixels)
		"highlight_padding"	: "30"
	},
	...
]);

*****************/

import { CommonModule } from '@angular/common';
import {
	Component,
	OnInit,
	NgModule,
	ComponentFactoryResolver,
	Injector,
	ApplicationRef,
	EmbeddedViewRef,
	ElementRef,
	ViewChild
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

declare var $: any;

@Component({
	selector: 'tour',
	templateUrl: 'tour.html',
	styleUrls: ['tour.scss'],
})
export class TourComponent implements OnInit {
	@ViewChild('highlighter', { static: true }) highlighter_ref: ElementRef;
	@ViewChild('overlay', { static: true }) overlay_ref: ElementRef;

	public module: any;

	public highlighter: any;
	public overlay: any;

	public ready: boolean = false;
	public hidden: boolean = false;
	public loading: boolean = true;

	public dialog_class: any = '';
	public title: string = '';
	public message: string = '';

	ngOnInit()
	{
		this.highlighter = $(this.highlighter_ref.nativeElement);
		this.overlay = $(this.overlay_ref.nativeElement);
		this.ready = true;
	}

	update(s)
	{
		this.dialog_class = s.dialog_class||'';
		this.title = s.title||'';
		this.message = s.message||'';
		this.loading = false;
	}

	hide()
	{
		this.hidden = true;
		if (typeof this.module.opts.onhide === 'function')
			{ this.module.opts.onhide(); }
	}
	show()
	{
		this.hidden = false;
	}
	prev()
	{
		this.module.prev();
	}
	next()
	{
		this.module.next();
	}
};

@NgModule({
  declarations: [ TourComponent  ],
  exports: [ TourComponent   ],
  imports: [
	  CommonModule,
	  MatCardModule,
	  MatIconModule,
	  MatButtonModule,
	  MatProgressSpinnerModule,
  ]
})
export class TourModule {

	private steps: [any];
	private selector: any;
	private selector_loop: any;
	private current_step: number = 0;
	private highlighter: any;
	private overlay: any;

	private component: any;

	private opts: any = {
		onhide: ()=>{}
	};

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private injector: Injector,
		private appRef: ApplicationRef
	) {}

	start(steps: [any], opts?)
	{
		this.current_step = 0;

		if (opts)
		{
			this.opts = $.extend(this.opts, opts);
		}

		if (steps.length||0 == 0)
			{ return; }

		this.steps = steps;
		this.load_component();

		let loop = setInterval(() => {
			if (this.component.instance.ready)
			{
				this.show_step(0);
				clearInterval(loop);
			}
		}, 500);

		let step = this.steps[this.current_step];
		$(window).resize(() => {
			this.show_step(this.current_step);
		});
	}

	show_step(index)
	{
		let step = this.steps[index];

		if (step.onstep)
		{
			step.onstep.forEach((script) => {
				eval(script);
			});
		}

		let times_fired = 0;
		if (this.selector_loop)
			{ clearInterval(this.selector_loop); }
		this.selector_loop = setInterval(() => {
			if (times_fired >= 40)
			{
				clearInterval(this.selector_loop);
				return;
			}
			this.position_highlighter(step.selector, step.highlight_padding);
			times_fired++;
		}, 50);

		switch (typeof step.dialog_position)
		{
			case 'string':
				step.dialog_class = step.dialog_position;
			break;

			case 'object': // responsive
				for (let k in step.dialog_position)
				{
					let width = parseInt(k.replace('px', ''));
					if (window.innerWidth > width)
					{
						step.dialog_class = step.dialog_position[k];
					}
				}
			break;

			default:
				step.dialog_class = '';
		}

		this.component.instance.update(step);
	}

	position_highlighter(selector?: string, padding?: number)
	{
		padding = +padding || 0;

		let x = 0;
		let y = 0;
		let w = 0;
		let h = 0;

		if (selector)
		{
			let target = $(selector).filter(':visible').first();
			if (target.length > 0)
			{
				let offset = target.offset();
				x = offset.left - padding/2;
				y = offset.top - padding/2;
				w = parseInt(target.outerWidth()) + padding;
				h = parseInt(target.outerHeight()) + padding;
			}
		}

		this.component.instance.highlighter.css({
			"left"		: x + "px",
			"top"		: y + "px",
			"width"		: w + "px",
			"height"	: h + "px",
		});
		if (this.component.instance.highlighter.css('display')=='none')
		{
			this.component.instance.highlighter.css('display', 'block');
		}
	}

	hide()
	{
		this.component.instance.hide();
	}
	show()
	{
		this.component.instance.show();
	}
	prev()
	{
		if (this.current_step <= 0)
			{ return; }
		this.current_step--;
		this.show_step(this.current_step);
	}
	next()
	{
		if (this.current_step >= this.steps.length-1)
			{ return; }
		this.current_step++;
		this.show_step(this.current_step);
	}

	private load_component()
	{
		this.component = this.componentFactoryResolver
			.resolveComponentFactory(TourComponent)
			.create(this.injector);

		this.component.instance.module = this;

		this.appRef.attachView(this.component.hostView);
		let domElem = (this.component.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    	document.body.appendChild(domElem);
	}
}
