import { Component, OnInit, Inject } from '@angular/core';
import { FilterService } from '../../service/filter.service';
import { UiService } from '../../service/ui.service';
import { scgFilter } from '../../definitions';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  providers: [ 
	MatDialog
  ]
})
export class FilterComponent implements OnInit
{
	public accordion : any;
	constructor(
		public fs: FilterService,
		public ui: UiService,
		public dialog: MatDialog
	) {
        this.accordion = {};
        if (this.fs.filters) {
            for (let option in this.fs.filters) {
                this.accordion[option] = false;
            }
        }
	}
	ngOnInit() {
	}
	togglefilter(name:string)
	{
        for (let key in this.accordion) {
            if (key != name) {
                this.accordion[key] = false;
            }
        }
		this.accordion[name] = !this.accordion[name];
	}
	
}


