import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatProgressBar } from '@angular/material/progress-bar';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../service/api.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

	public spinner: boolean = false;
	public contact_form: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required, Validators.email]),
		phone: new FormControl('', [Validators.pattern("(?:(?:\\+?1\s*(?:[.-]\s*)?)?(?:(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})")]),
		message: new FormControl()
	});
	public form_error: string = '';

	public view: string = 'form';

	public community: any;

	constructor(
		public dialogRef: MatDialogRef<ContactComponent>,
		private _fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private api: ApiService
	)
	{
		this.community = data.community;
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
	ngOnInit() {

	}

	get_error(field_name)
	{
		let field = this.contact_form.get(field_name);
		if (!field || field.valid)
			{ return; }

		var msg = '';
		switch (field_name)
		{
			case 'name':
				msg = "Please enter a valid name";
			break;

			case 'phone':
				msg = "Please enter a valid phone";
			break;

			case 'email':
				msg = "Please enter a valid email";
			break;
		}

		return msg;
	}

	change_view(view: string)
	{
		this.view = view;
	}

	submit()
	{
		this.change_view('spinner');

		let name	= this.contact_form.get('name');
		let email	= this.contact_form.get('email');
		let phone	= this.contact_form.get('phone');
		let message	= this.contact_form.get('message');

		let values = {
			location_id	: this.community.id,
			name		: name.value,
			email		: email.value,
			phone		: phone.value,
			message		: message.value
		};


		if (!this.contact_form.valid)
		{
			this.change_view('form');
			return;
		}

		this.api.post('send_community_email',values ).subscribe((data: any) => {
			let res = data;

			this.change_view('success');

			if (!res.success)
			{
				this.form_error = res.error||'Failed to send';
				return;
			}

		});
	}
}

