import { Component, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingsService } from '../../service/settings.service';
import { MapService } from '../../service/map.service';
@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss']
})
export class SortComponent{
	constructor(
    	public dialogRef: MatDialogRef<SortComponent>,
    	public s: SettingsService,
		public map: MapService
	)
	{}
  	setting_change(e,name)
	{
		this.s.changed(e,name);
		this.map.updateCommunities(() => {
		});
	}
}
