import { Component } 						from '@angular/core';
import { ActivatedRoute, ParamMap } 		from '@angular/router';
import { Location } 						from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SettingsService } 					from '../../service/settings.service';
import { ApiService } 						from '../../service/api.service';
import { Observable } 						from 'rxjs';
import { UiService } 						from '../../service/ui.service';
import { AnalyticsService } 				from '../../service/analytics.service';

import { ContactComponent }					from '../contact/contact.component';
import { RequestInfoComponent }				from '../request-info/request-info.component';
import { PhoneComponent }					from '../phone/phone.component';
declare var jQuery: any;

import 'rxjs/add/operator/switchMap';

@Component({
  selector: 'app-floorplan',
  templateUrl: './floorplan.component.html',
  styleUrls: ['./floorplan.component.scss']
})
export class FloorplanComponent{
	community_id	: number;
	plan_index		: number;
	community 		: any;
	plan 			: any;
	tour			: any;
	form			: any;

  	constructor(
		private s : SettingsService,
		private ui : UiService,
		private api : ApiService,
		private route : ActivatedRoute,
		private _location: Location,
		public dialog: MatDialog,
		private analytics: AnalyticsService
	) {
		this.form = {};

		if(this.s.config)
		{
			this.init();
		}
		else
		{
			this.s.$settings.subscribe((data) => {
				this.init();
				console.log("CommunityComponent init");
			});
		}
	}

	init() {
		this.route.paramMap
			.switchMap((params:ParamMap) => {
				this.plan_index = +params.get('planindex')
				this.community_id = +params.get('community')

				this.ui.set_parent_path('/community/'+this.community_id);

				return this.api.community(this.community_id);

			})
			.subscribe(community => {
				this.community 				= community;
					this.plan 				= this.community.floorplans[this.plan_index];
					this.tour				= this.get_floorplan_3d(this.plan);
				console.log(this.community_id,this.plan_index,this.plan);
				if(jQuery.fn)
				{
					setTimeout(()=>{
						this.ui.position_contacts();
					},100);
				}
			});
	}

	public request_info()
	{
		// send user to Email
		let dialogRef = this.dialog.open(RequestInfoComponent, {
			width: '450px',
			data: { community: this.community }
		});

		dialogRef.afterClosed().subscribe(result => {
			this.form = result;
		});

		if (this.community.id)
		{
			this.analytics.event('request_info', {
				location_id: this.community.id,
				type: 'email'
			});
		}
	}

	public email()
	{
		// send user to Email
		let dialogRef = this.dialog.open(ContactComponent, {
			// width: '450px',
			data: {
				community: this.community
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			this.form = result;
		});

		if (this.community.id)
		{
			this.analytics.event('request_info', {
				location_id: this.community.id,
				type: 'email'
			});
		}
	}

	public phone()
	{
		// send user to Phone
		let dialogRef = this.dialog.open(PhoneComponent, {
			// width: '450px',
			data: {
				community: this.community
			}
		});

		if (this.community.id)
		{
			this.analytics.event('request_info', {
				location_id: this.community.id,
				type: 'phone'
			});
		}
	}

	backClicked() {
		this._location.back();
	}

	hasAvailability(){
		if(this.plan.availability > 0){
			console.log('AVAILABLE!');
		}else{
			console.log('UNAVAILABLE');
		}
	}

	get_floorplan_3d(plan)
	{
		let tours = this.community.misc['3d_tour']||[];
		let match;
		for (let i in tours)
		{
			let tour = tours[i];
			if (tour.label == plan.label)
			{
				match = tour.value;
				break;
			}
		}

		return match;
	}

}
