import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchComponent } from '../component/search/search.component';
import { FilterComponent } from '../component/filter/filter.component';
import { CommunityComponent } from '../component/community/community.component';
import { SettingsComponent } from '../component/settings/settings.component';
import { AccountComponent } from '../component/account/account.component';
import { FloorplanComponent } from '../component/floorplan/floorplan.component';
import { FavoritesComponent } from '../component/favorites/favorites.component';

const appRoutes: Routes = [
	// { path: 'search', component: SearchComponent },
	{ path: 'search/:searchstr', component: SearchComponent },
	{ path: 'favorites', component: FavoritesComponent },
	{ path: 'settings', component: SettingsComponent },
	{ path: 'account', component: AccountComponent },
	{ path: 'community/:id', component: CommunityComponent },
	{ path: 'floorplan/:community/:planindex', component: FloorplanComponent },
	// {
	// 	path: '',
	// 	redirectTo: '/search/',
	// 	pathMatch: 'full'
	// },
	{
		path: '**',
		redirectTo: '/search/',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(
			appRoutes
		)
	],
	exports: [
		RouterModule
	],
	declarations: []
})
export class RoutingModule { }
