import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as jwtDecode from 'jwt-decode';
import { SettingsService } from './settings.service';
import { AnalyticsService } from './analytics.service';
import { ApiService } from './api.service';

declare var ga: any;

@Injectable()
export class AccessService {

	public odin_access_id: number;
    public user_data: any;
	public auth: {
		id_token		: string,
		access_token	: string,
		expires_in		: number,
		expiration		: number,
		token_type		: string
	};

	private api_host: string;
	private headers = new HttpHeaders({
		"Access-Control-Allow-Methods" : "GET, POST",
		'Content-Type': 'application/json'
	  });
	
	private login_provider_url: string;
	private mock_form_promise: any = {
		resolve: () => {},
		reject: () => {}
	};

    constructor(
		private router: Router,
		private http: HttpClient,
		private analytics: AnalyticsService,
		private s : SettingsService,
		private api: ApiService,
	)
	{
	}

	public sign_out()
	{
		// remove server-side session
		// this.generate_mock_form({}, this.api_basepath + '/auth/sign_out').then(() => {
		this.generate_mock_form({}, this.api.api_uri + '/auth/sign_out').then(() => {
			// success
		}, console.error);

		// remove client-side data
		this.auth		= null;
		this.user_data	= null;

		this.router.navigate(['/']);
	}

	public login_provider(username: string = '', password: string = '', confirm: string = '')
	{
		return new Promise((resolve, reject) => {
			this.generate_mock_form({
				username: username,
				password: password,
				confirm: confirm,
			}).then((data: any) => {
				// if (typeof data !== 'object' || data.token_type !== 'Bearer')
				if (typeof data !== 'object' || data.error)
				{
					reject(data);
					return;
				}

				console.log('AUTH', data);
				this.auth = data;
				if (data.id_token)
				{
					this.user_data = jwtDecode(data.id_token);
					this.odin_access_id = this.user_data.uid;

					this.analytics.register_user(this.odin_access_id);
				}

				resolve(data); // send data to caller as well
			}, reject);
		});
	}
	private generate_mock_form(data, url='')
	{
		return new Promise((resolve, reject) => {
			// create login provider iframe
			var frame: any		= document.getElementsByName('login_provider')[0];
			if (!frame)
			{
				frame		= document.createElement('iframe');
				frame.name	= 'login_provider';
				frame.id	= frame.name;
				frame.src	= 'about:blank';
				frame.style.display	= 'none';
				document.body.appendChild(frame);
			}
			window.addEventListener('message', (msg) => {
				if (msg.origin !== this.api_host)
					{ return; }

				resolve(msg.data);
			});

			// create mock form
			var form: any	= document.getElementById('login_provider_form');
			if (!form)
			{
				form = document.createElement('form');
				form.id			= 'login_provider_form';
				form.target		= 'login_provider';
				form.method		= 'POST';
				document.body.appendChild(form);
			}
			form.action		= url || this.login_provider_url;
			while(form.firstChild)
				{ form.removeChild(form.firstChild); }
			for (var k in data)
			{
				let input	= document.createElement('input');
				input.name	= k;
				input.value	= data[k];
				form.appendChild(input);
			}
			form.style.display = 'none';

			form.submit();
		});
	}

    public google_signin(user)
    {
		return new Promise((resolve, reject) => {
			var id_token		= user.Zi.id_token;
			var access_token	= user.Zi.access_token;
			if (!id_token)
				{ reject("Must provide `id_token`"); }
			if (!access_token)
				{ reject("Must provide `access_token`"); }

			this.api_call('/auth/google_login', {
				id_token: id_token,
				access_token: access_token,
			}).then((auth_data: any) => {
				this.auth = auth_data;
				resolve(auth_data);
			}, reject);
		});
    }

	public password_signin(username, password)
	{
		return new Promise((resolve, reject) => {
			this.api_call('/auth/request_token', {
				grant_type	: 'password',
				username	: username,
				password	: password,
			}).then((auth_data: any) => {
				console.log(auth_data);
				this.auth = auth_data;
				resolve(auth_data);
			}, reject);
		});
	}

	public request_access_token(refresh_token='')
	{
		return new Promise((resolve, reject) => {
			this.api_call('/auth/request_token', {
				grant_type		: 'refresh_token',
				refresh_token	: refresh_token
			}).then((auth_data: any) => {
				this.auth = auth_data;
				resolve(auth_data);
			}, reject);
		});
	}

	
	public get_token(refresh_token=''): any
	{
		var t = new Date().getTime() / 1000;
		if (this.auth && this.auth.expiration > t)
		{
			return new Promise((resolve, reject) => {
				resolve(this.auth);
			});
		}

		// request new token
		return this.login_provider();
	}

	private api_call(api, params)
	{
		return new Promise((resolve, reject) => {
			// this.http.post(this.api_basepath + api, params, {
			this.http.post(this.api.api_uri + api, params, {
				headers: this.headers
			}).subscribe((data: any) => {
				// success
				resolve(JSON.parse(data._body));
			}, (data: any) => {
				reject(JSON.parse(data._body));
			});
		});

	}


	
}
