import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MapService } from '../../service/map.service';
import { ApiService } from '../../service/api.service';
import { AnalyticsService } from '../../service/analytics.service';
import { SettingsService } from '../../service/settings.service';
import { FilterService } from '../../service/filter.service';
import { UiService } from '../../service/ui.service';
import { UserdataService } from '../../service/userdata.service';

import { ContactComponent } from '../contact/contact.component';
import { RequestInfoComponent } from '../request-info/request-info.component';
import { PhoneComponent } from '../phone/phone.component';

import 'rxjs/add/operator/switchMap';

import { Title } from '@angular/platform-browser'; //names

declare var google: any;
declare var jQuery: any;

@Component({
	selector: 'app-community',
	templateUrl: './community.component.html',
	styleUrls: ['./community.component.scss'],
})
export class CommunityComponent {
	community: any;
	banner: any;
	filter_matches: any;
	plans: any;
	zoom: number;
	marker: any;
	form: any;
	constructor(
		private titleService: Title, //title
		public api: ApiService,
		public fs: FilterService,
		public map: MapService,
		private router: Router,
		private route: ActivatedRoute,
		private s: SettingsService,
		private ui: UiService,
		private userdata: UserdataService,
		private sanitizer: DomSanitizer,
		public dialog: MatDialog,
		private analytics: AnalyticsService
	) {
		this.form = {};
		this.zoom = 14;
		if (this.s.config) {
			this.init();
		}
		else {
			this.s.$settings.subscribe((data) => {
				this.init();
			});
		}
	}
	init() {
		this.fs.get_selected();
		this.filter_matches = [];
		// this.api.community()
		this.route.paramMap
			.switchMap((params: ParamMap) => this.api.community(+params.get('id')))
			.subscribe(community => {
				this.banner = community.banner || community.gallery[0] || false;
				if (this.banner && community.banner) { community.gallery.unshift(community.banner); }
				this.community = community;
				// Set the page title to the community's name
				if (this.community && this.community.name) {
					this.titleService.setTitle(`${this.community.name} - Senior Communities Guide`);
				}
				this.banner = community.banner || community.gallery[0] || false;
				if (this.banner && community.banner) { community.gallery.unshift(community.banner); }
				this.community = community;
				let score_data = this.fs.get_community_score(community);
				let score = this.fs.calculate_score(score_data);
				this.community.score_data = score_data;
				this.community.score = score;
				if (this.community.contacts && this.community.contacts.length > 0) {
					let c = this.community.contacts[0];
					if (this.community.email == '' && c.email != '') {
						this.community.email = c.email;
					}
					if (this.community.phone == '' && c.phone != '') {
						this.community.phone = c.phone;
					}
				}

				this.filter_matches = this.matches();
				this.plans = this.floorplans();
				this.ui.set("community_page", this.community);
				this.map.set_center(this.community.address.geo);

				if (jQuery.fn) {
					setTimeout(() => {
						this.ui.position_contacts();
					}, 100);
				}
			});
	}
	is_favorite() {
		if (!this.community) { return false; }
		return this.userdata.hasfavorite(this.community.id) !== false;
	}
	toggle_favorite() {
		if (!this.community) { return false; }
		if (this.is_favorite()) {
			this.userdata.removefavorite(this.community.id)
		}
		else {
			this.userdata.addfavorite(this.community.id)
		}
		return;
	}

	favorite_icon() {
		return this.is_favorite() ? "favorite" : "favorite_border";
	}
	mapready(map) {
		this.marker = new google.maps.Marker({
			position: this.community.address.geo,
			map: map,
			title: this.community.name,
			icon: {
				url: this.community.member ? '../../assets/icon-teal.png' : '../../assets/icon-grey.png',
				labelOrigin: new google.maps.Point(24, 24)
			}
		});
		this.map.set_map_style(map);
	}
	format_price(p) {
		return "$" + (parseFloat(p).toLocaleString());
	}
	floorplans() {
		let f: any = [];
		if (this.community && this.community.floorplans) {
			f = this.community.floorplans;
			for (let i in f) {
				let plan = f[i];
				let tour = this.get_floorplan_3d(plan);
				if (tour) { plan.tour = tour; }
			}
		}
		return f;
	}
	get_floorplan_3d(plan) {
		let tours = this.community.misc['3d_tour'] || [];
		let match;
		for (let i in tours) {
			let tour = tours[i];
			if (tour.label == plan.label) {
				match = tour.value;
				break;
			}
		}

		return match;
	}

	goto_floorplan(i) {
		let page_path = "/floorplan/" + this.community.id + "/" + i;
		this.router.navigate([page_path]);
	}
	matches() {
		let m = [];
		if (!this.community) {
			return m;
		}
		let c = this.community;
		if (c.score_data) {
			for (var key in c.score_data) {
				if (
					c.score_data.hasOwnProperty(key) &&
					c.score_data[key] &&
					key != "distance" &&
					c.score_data[key].m
				) {
					m = m.concat(c.score_data[key].m);

					// this.analytics.event('community_filter_match', {
					// 	location_id: c.id,
					// 	match_type: key,
					// 	match_value: m
					// });
				}
			}
		}
		return m;
	}

	has(obj, type = "object") {
		if (obj && typeof obj == type) {
			let ret = false;
			switch (type) {
				default:
					ret = obj != "";
					break;
				case "object":
					ret = Object.keys(obj).length > 0;
					break;

			}
			return ret;
		}

		return false;
	}

	wholenumber(p) {
		return Math.round(p);
	}

	public request_info() {
		// send user to Email
		let dialogRef = this.dialog.open(RequestInfoComponent, {
			width: '450px',
			data: { community: this.community }
		});

		dialogRef.afterClosed().subscribe(result => {
			this.form = result;
		});

		if (this.community.id) {
			this.analytics.event('request_info', {
				location_id: this.community.id,
				type: 'email'
			});
		}
	}

	public email() {
		// send user to Email
		let dialogRef = this.dialog.open(ContactComponent, {
			// width: '450px',
			data: {
				community: this.community
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			this.form = result;
		});

		if (this.community.id) {
			this.analytics.event('request_info', {
				location_id: this.community.id,
				type: 'email'
			});
			this.api.log(this.community.id, 'view_email').subscribe((data: any) => { });
		}
	}

	public phone() {
		// send user to Phone
		let dialogRef = this.dialog.open(PhoneComponent, {
			// width: '450px',
			data: {
				community: this.community
			}
		});

		if (this.community.id) {
			this.analytics.event('request_info', {
				location_id: this.community.id,
				type: 'phone'
			});

			this.api.log(this.community.id, 'view_phone').subscribe((data: any) => { });

		}

	}

	public community_link() {
		if (this.community.id) {
			this.analytics.event('request_info', {
				location_id: this.community.id,
				type: 'external_link'
			});

			this.api.log(this.community.id, 'external_link').subscribe((data: any) => { });

		}

	}

	public analytics_action(action) {
		if (!this.community.id) { return; }

		switch (action) {
			case 'video_thumb_click':
				this.analytics.event('video_thumb_click', {
					location_id: this.community.id
				});
				break;

			case 'image_interaction':
				this.analytics.event('image_interaction', {
					location_id: this.community.id
				});
				break;
		}
	}


	has_resources() {
		if (typeof this.community.files !== 'object') { return false; }

		let files = this.community.files;

		if (!Array.isArray(files.events) && !Array.isArray(files.menus) && !Array.isArray(files.other)) { return false; }
		if (files.events.length + files.menus.length + files.other.length === 0) { return false; }

		// Ensure front-end won't break because of api
		if (!Array.isArray(files.events)) { files.events = []; }
		if (!Array.isArray(files.menus)) { files.menus = []; }
		if (!Array.isArray(files.other)) { files.other = []; }

		return true;
	}

}
