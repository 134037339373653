import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { SettingsService } from './settings.service';
import { scgFilter } from '../definitions';
import { filter, max } from 'rxjs/operators';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';

declare var google: any;

@Injectable()
export class FilterService implements OnInit, OnDestroy {

	public filters: scgFilter[];
	public weights: any;
	public sort_order: number[];
	public on_map: number[];
	public settings: any;
	public selected;
	public map_props: any;
	public result_count: any;
	public all_communities: any;
	public lookup: any;
	public autoOpenFilters: boolean;
	public budget_range: number[];
	constructor(
		private api: ApiService,
		private store: StorageService,
		private s: SettingsService
	) {
		this.result_count = 20;
		this.s.$settings.subscribe((data) => {
			this.init();
		});
	}

	ngOnInit() {
	}
	init() {
		this.get_options().subscribe();
		this.map_props = this.s.get_map_settings();
		this.sort_order = [];
		this.on_map = [];
		this.lookup = [];
		this.all_communities = {};
		this.get_selected();
		this.get_settings();
		this.budget_range = this.get_budget_range();


		this.result_count = +this.s.setting('show_total') || 50;
	}
	ngOnDestroy() {
		this.save_filters();
	}

	save_filters() {
		// this needs to wait and fire at the end of the stack
		setTimeout(() => {
			if (this.selected) {
				this.store.set("selected_filters", this.selected);
				this.s.save();
			}
		}, 0);
	}
	get_selected() {
		if (!this.selected) { this.selected = this.store.get("selected_filters") || {}; }
		if (this.s.saved['show_total']) {
			this.result_count = +this.s.saved['show_total'];
		}
		return this.selected;
	}
	get_settings() {
		this.s.load_settings();
		return this.s.settings;
	}
	filters_count(name = '', show_text = false) {
		let count = 0;
		let f = this.selected;
		let tota_of_name = function (f, name) {
			let count = 0;
			if (f[name]) { for (const n in f[name]) { if (f[name][n]) { count++; } } }
			return count;
		};
		if (name == '') {
			for (const key in f) { if (f.hasOwnProperty(key)) { count += tota_of_name(f, key); } }
		}
		else { count += tota_of_name(f, name); }
		if (show_text && count == 0) { return; }
		return count;
	}
	get_options() {
		return new Observable(observer => {

			if (!this.filters) {
				this.api.options().subscribe((options) => {
					if (!this.selected) { this.get_selected(); }

					this.filters = options.filters;
					this.weights = options.weights;
					this.filters.forEach(item => {
						if (item && !this.selected[item.name]) { this.selected[item.name] = {}; }
					});
					observer.next(this.filters);
				},
					() => { },
					() => {
						observer.complete();
					});
			}
			else {
				observer.next(this.filters);
				observer.complete();
			}
		});
	}
	get_budget_range() {
		let budget = this.selected.budget || false;
		let min = -1;
		let max = -1;
		if (!budget) {
			return [min, max];
		} else {
			for (var key in budget) {
				if (budget.hasOwnProperty(key) && budget[key]) {
					let r = key.replace(/[^0-9\-\.]/g, '').split("-").map((v) => { return parseFloat(v) });
					if (Math.min.apply(this, r) < min || min == -1) { min = Math.min.apply(this, r) };
					if (Math.max.apply(this, r) > max || max == -1) { max = Math.max.apply(this, r) };
				}
			}
		}
		return [min, max];
	}
	add_communities(communities) {
		for (let id in communities) {
			let c = communities[id];
			for (let k in c) {
				if (!this.all_communities[id]) {
					console.log("no community?", c);
				}
				if (!this.all_communities[id][k]) { this.all_communities[id][k] = c[k]; }
			}
			// remove this id from the lookup array as it has been added!
			this.lookup.splice(this.lookup.indexOf[id], 1);
		}
	}
	sort_communities() {
		let coms = [];
		let selected_match = [];
		let selected_refine = [];
		let this_selected = this.selected
		this.budget_range = this.get_budget_range();

		// //seperate out the 2 types of filters
		if (this.selected) {
			let sel_keys = Object.keys(this.selected);
			sel_keys.forEach(k => {
				let any_true = false;
				if (Object.keys(this_selected[k]).length > 0) {
					Object.keys(this_selected[k]).forEach(sel_k => {
						if (this_selected[k][sel_k]) { any_true = true };
					});
				}
				if (any_true) {
					this.filters.forEach(filt => {
						if (filt.name == k) {
							if (filt.type == 'match') {
								selected_match[k] = this_selected[k];
							} else if (filt.type == 'refine') {
								selected_refine[k] = this_selected[k];
							}
						}
					});
				}
			});
		}

		if (this.on_map.length == 0) { console.log("no communities yet"); return null; }
		for (let i = 0, l = this.on_map.length; i < l; i++) {
			let id = this.on_map[i];
			let filter_out_com = false;
			if (this.all_communities[id]) {
				let c = this.all_communities[id];
				if (Object.keys(selected_refine).length > 0) {
					Object.keys(selected_refine).forEach(cat => {
						if (cat != 'budget') {
							Object.keys(selected_refine[cat]).forEach(child => {
								if (selected_refine[cat][child]) {
									if (!c[cat].includes(child)) { filter_out_com = true; }
								}
							});
						}
					});
				}
				if (filter_out_com) {
					delete this.on_map[i]
				} else {
					let score_data = this.get_community_score(c);
					let score = this.calculate_score(score_data);
					if (!score_data['budget'] || score_data['budget']['p'] == 0) {
						delete this.on_map[i];
					} else {
						this.all_communities[id].score_data = score_data;
						this.all_communities[id].score = score;
						coms.push(this.all_communities[id]);
						// do we need to load this communities information?
						if (!c.name) {
							this.lookup.push(+id);
						}
					}
				}

			}
		}

		//set default setting if no sort is saved
		if(!this.s.saved.sort){
			this.s.saved.sort = "distance";
		}

		// pick sorting method
		var sortmethod = 'score_sort';
		if (typeof this['score_sort_' + this.s.saved.sort] == "function") {
			sortmethod = 'score_sort_' + this.s.saved.sort;
		}
		// Sort those communities!
		coms.sort((a, b) => {
			// members first no matter what
			let s = this.member_sort(a, b);
			if (s != 0) { return s; }

			// get the sorting algorithims in order
			let sorts = this['sort_order_option_members'](sortmethod);

			// run all the sorts
			for (let i = 0, l = sorts.length; i < l; i++) {
				s = this[sorts[i]](a, b);
				if (s != 0) { break; }
			}
			return s;
		});

		// update order
		this.sort_order = [];
		let price_sort = false;
		if(this.s.saved.sort == 'low' || this.s.saved.sort == 'high'){
			price_sort = true;
		}
		for (let key in coms) {
			if (coms.hasOwnProperty(key)) {
				let com = coms[key];
				this.all_communities[+com.id].sort_index = this.sort_order.length + 1;
				// change the text on the marker
				if(price_sort && (!com.budget_range[0] || !com.budget_range[1])){	
				} else {
					this.sort_order.push(parseInt(com.id));
					if (this.result_count <= this.sort_order.length) {
						console.log("Stop here, view limit is ", this.sort_order.length);
						break;
					}
				}
			}
		}
	}
	// Decide Sort Order
	member_sort(a, b) {
		return ( ((b.tier > 1)? 1 : 0) - ((a.tier > 1)? 1 : 0) );
	}
	sort_order_option_members(method) {
		let order = this.s.saved.sortorder;
		if (order && order == "type") {
			return [method, 'score_sort'];
		}
		return ['score_sort', method];
	}
	// sort_order_option_non_member(method) {
	// 	return ['score_sort_distance'];
	// }
	score_sort(a, b) {
		return b.score - a.score;
	}
	score_sort_distance(a, b) {
		return b.score_data.distance.p - a.score_data.distance.p;
	}
	score_sort_low(a, b) {
		return ((a.budget_range[0]? a.budget_range[0]: 99999) - (b.budget_range[0]? b.budget_range[0]: 99999));
	}
	score_sort_high(a, b) {
		return b.budget_range[1] - a.budget_range[1];
	}
	// score_sort_low(a, b) {
	// 	return a.budget_range[0] - b.budget_range[0];
	// }
	// score_sort_high(a, b) {
	// 	return b.budget_range[1] - a.budget_range[1];
	// }

	// ! Filter Methods
	calculate_score(score) {
		// update settings
		this.get_settings();

		let total = 0;
		let items = 0;
		let item_names = [];
		for (var key in score) {
			if (score.hasOwnProperty(key) && key != "distance" && score[key] && this.weights && score[key]) {
				let si = parseFloat(score[key].p);
				let w = parseFloat(this.weights[key]) || 1;
				total += (si * w);
				items++;
				item_names.push(key);
			}
		}

		total = total / items;
		if (total > 100) { total = 100; }
		else if (total < 0) { total = 0; }
		if (items > 0) {
			return Math.round(total * 100000) / 1000;
		}
		return 100;
	}
	get_community_score(c) {
		if (!c) { return null; }

		let score: any =
		{
			distance: this.score_distance(c.geo),
			budget: c.budget_range ? this.score_budget(c.budget_range[0], c.budget_range[1]) : 0,
		};
		if (this.filters) {
			let skip = ['distance', 'budget']; // don't change above values
			this.filters.forEach((filter) => {
				if (skip.indexOf(filter.name) !== -1) { return; }
				score[filter.name] = this.score_matches(filter.name, c[filter.name]);
			});
		}
		return score;
	}
	score_distance(geo) {
		let score = 0;
		let km = 0;
		if (geo && this.map_props) {
			km = this.distance_between(geo, this.map_props.center);
			let s = this.map_props.scale / 2;
			if (s > 0) { score = (s - km) / s; }
		}
		return { p: score, km: km, mile: (km * 0.621371) };
	}
	score_budget(min_source, max_source) {
		let score = 0;
		let matches = [];

		if (!this.selected) { return false; }
		if(this.budget_range[0] == -1 && this.budget_range[1] == -1){
			score = 1;
		} else {
			if (max_source < this.budget_range[0] || min_source > this.budget_range[1]) {
				score = 0;
			} else if(min_source && max_source){
				score = 1;
			}
		}
		return { p: score, m: matches };
	}
	score_matches(type, search) {
		let found = 0;
		if (!this.selected) { return false; }
		let items = this.selected[type];
		let total = 0;
		let matches = [];
		if (items) {
			for (var key in items) {
				if (items.hasOwnProperty(key) && items[key]) {
					if (search.indexOf(key) >= 0) { found++; matches.push(key); }
					total++;
				}
			}
		}
		if (!total) { return false; }
		return { p: found / total, m: matches };
	}
	// ! Helper Methods

	// add the map scale: km from top left to bottom right
	update_props() {
		this.map_props.scale = 100;
		if (!this.map_props || !this.map_props.bounds) { return; }
		this.map_props.scale = this.distance_between({
			lat: this.map_props.bounds.north,
			lng: this.map_props.bounds.west
		}, {
			lat: this.map_props.bounds.south,
			lng: this.map_props.bounds.east
		});
	}
	// get the distance between 2 coordinates on a sphere
	distance_between(geo1, geo2) {
		let R = 6371; // Radius of the earth in km
		let dLat = this.deg2rad(geo2.lat - geo1.lat);  // deg2rad below
		let dLon = this.deg2rad(geo2.lng - geo1.lng);
		let a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(this.deg2rad(geo1.lat)) * Math.cos(this.deg2rad(geo2.lat)) *
			Math.sin(dLon / 2) * Math.sin(dLon / 2);

		let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		let d = R * c; // Distance in km
		return d;
	}

	deg2rad(deg) { return deg * (Math.PI / 180); }
}
