<div class="slider-slides-container">
	<ng-content></ng-content>
</div>
<div class="slider-action-container slider-left-container" *ngIf="show_controls()"><button type="button" (click)="prev_slide()"><mat-icon>keyboard_arrow_left</mat-icon></button></div>
<div class="slider-action-container slider-right-container" *ngIf="show_controls()"><button type="button" (click)="next_slide()"><mat-icon>keyboard_arrow_right</mat-icon></button></div>
<div class="slider-position-container" *ngIf="total > 1">{{ index+1 }} / {{ total }}</div>
<div class="spinner-overlay" [ngClass]="{'hide': !loading}">
	<div class="spinner">
		<mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
	</div>
</div>
