<mat-list class="filter-list">
    <mat-accordion multi="false">

        <ng-container *ngFor="let option of fs.filters">
            <mat-expansion-panel>
                <mat-expansion-panel-header (click)="togglefilter(option.name)">
                    <mat-panel-title>
                        <mat-basic-chip disabled *ngIf="fs.filters_count(option.name,1);else other_content" color="primary" id="has-match">
                            <span class="selected">{{fs.filters_count(option.name,1)}}</span>
                        </mat-basic-chip>
                        <!-- <ng-template #other_content><span class="dot-spacer"></span></ng-template> -->
                        <ng-template #other_content>
                            <mat-basic-chip disabled color="grey" id="empty-chip">
                                <span class=""></span>
                            </mat-basic-chip>
                        </ng-template>
                        {{option.label}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container>
                    <p>{{option.description}}</p>
                    <mat-list-item *ngFor="let value of option.values">
                        <mat-checkbox [(ngModel)]="fs.selected[option.name][value]" (click)="fs.save_filters()" class="filter-checkbox">
                            {{value}}
                        </mat-checkbox>
                    </mat-list-item>
                </ng-container>
            </mat-expansion-panel>
        </ng-container>
    
    </mat-accordion>
</mat-list>
