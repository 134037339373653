import { Injectable, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AgmMap, MapsAPILoader, GoogleMapsAPIWrapper, LatLngLiteral } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { LatLng, Bounds, MapProps, community } from '../definitions';
import { ApiService } from '../service/api.service';
import { AnalyticsService } from '../service/analytics.service';
import { FilterService } from '../service/filter.service';
import { UiService } from '../service/ui.service';
import { SettingsService } from '../service/settings.service';
import { Observable } from 'rxjs';

import '../library/marker_clusterer.js';

import { ThrowStmt } from '@angular/compiler';

declare var window: any;
declare var document: any;
declare var google: any;
declare var MarkerClusterer: any;
declare var jQuery: any;
@Injectable()
export class MapService implements OnInit {
	inst;
	userPos: LatLng;
	selected_community: any;
	ids: number[];
	communities: any;
	shown_communities: any;
	total_communities: number;
	total_shown_communities: number;
	zoom: number;
	lastzoom: number;
	min_zoom: number;
	max_zoom: number;
	maxbounds: Bounds;
	request: any;
	infowindow: any;
	update_communities: boolean;
	clusters: any;
	open: boolean;
	mapstyle: any;
	autocomplete_element: any;
	marker_total: number;
	marker_index: number;
	timers: any;
	features: any;
	featureids: any;
	search_element: any;
	search_term: string;
	search_last: string;
	zoomto_default: number;
	can_force_zoomout: boolean;
	lookingUpAddress: boolean;
	last_props: any;
	constructor(
		private api: ApiService,
		private analytics: AnalyticsService,
		private gmw: GoogleMapsAPIWrapper,
		private fs: FilterService,
		private ui: UiService,
		private s: SettingsService,
		private http: HttpClient,
		private router: Router
	) { }

	ngOnInit() {
		this.mapstyle = {};
		this.timers = {};
		this.lookingUpAddress = false;
	}

	init(map) {
		this.inst = map;
		this.open = true;
		this.update_communities = true;
		this.zoomto_default = 12;
		this.max_zoom = 17;
		this.min_zoom = 9;
		this.can_force_zoomout = false;
		this.request = {};
		this.timers = {};
		this.communities = {};
		this.shown_communities = {};
		this.ids = [];
		this.features = [];
		this.featureids = [];

		let map_pos = this.s.get_map_settings();
		this.zoom = map_pos.zoom || this.inst.getZoom();
		this.lastzoom = this.zoom + 0;

		if (map_pos.center) { this.set_center(map_pos.center); }
		else { map_pos.center = this.get_center(); }

		if (this.zoom < this.min_zoom) {
			this.inst.setZoom(this.min_zoom);
			this.zoom = this.min_zoom;
			map_pos.zoom = this.zoom;
		}
		if (this.zoom > this.max_zoom) {
			this.inst.setZoom(this.max_zoom);
			this.zoom = this.max_zoom;
			map_pos.zoom = this.zoom;
		}

		if (map_pos.zoom) { this.inst.setZoom(map_pos.zoom); }


		this.inst.addListener('zoom_changed', () => {
			this.lastzoom = 0 + this.zoom;
			this.zoom = this.inst.getZoom();
		});


		// !marker events!
		this.inst.data.addGeoJson({ "type": "FeatureCollection", "features": this.features });
		this.inst.data.addListener('mouseout', (e) => {
			clearTimeout(this.timers.quick_hover);
			let id = e.feature.getId();
			if (this.timers.recent_action) { return; }
			this.selected_community = null;
			this.update_features();
		});
		this.inst.data.addListener('mouseover', (e) => {
			clearTimeout(this.timers.quick_hover);
			let id = e.feature.getId();
			let c = this.fs.all_communities[id];
			this.timers.quick_hover = setTimeout(() => {
				if (c) {
					this.scrollto_community(id);
					this.selected_community = c;
					this.update_features();
				}
			}, 1000);
			this.markeraction(c, 'mouseover');
		});
		this.inst.data.addListener('click', (e) => {
			let id = e.feature.getId();
			let c = this.fs.all_communities[id];
			let item = document.getElementById("community-" + id);
			let mobile = this.ui.ismobile();
			if (mobile && jQuery) { jQuery("#togglemapbutton").click(); }
			if (id) { this.scrollto_community(id); }
			this.markeraction(c, 'click');
		});
		this.inst.data.addListener('dblclick', (e) => {
			let id = e.feature.getId();
			this.router.navigate(['/community/' + id]);
		});
		this.inst.addListener('idle', () => {
		});
		this.inst.addListener('drag', () => {
			this.can_force_zoomout = true;
		});

		this.set_map_style(this.inst);

		window.mapservice = this;
		this.init_autocomplete();
		this.ui.resize_loop(null, null, () => {
			this.set_center(map_pos.center);
			// setTimeout(()=>{
			//     this.set_center(map_pos.center);
			// },2000);
		});
	}
	scrollto_community(id) {
		let c = this.fs.all_communities[id];
		let item = document.getElementById("community-" + id);
		if (item) {
			let mobile = this.ui.ismobile();
			let resultsarea = this.ui.get("resultsarea").nativeElement;
			let scroll_distance = 0;
			// calculate and scroll for mobile
			if (mobile) {
				let sidenav = this.ui.get("sidenavContainer");
				let map = this.ui.get("agmmaparea").nativeElement;
				sidenav = sidenav._element.nativeElement;

				resultsarea = jQuery("mat-sidenav-content.mat-drawer-content");

				if (resultsarea.length > 0) {
					scroll_distance = item.offsetTop - (map.offsetHeight / 3);
				}
			}
			// calculate and scroll for desktop
			else {
				scroll_distance = item.offsetTop - 200;
			}
			if (scroll_distance) {
				if (jQuery && jQuery.fn.animate) {
					jQuery(resultsarea).animate({
						"scrollTop": scroll_distance + "px"
					}, 300);
				}
				else {
					resultsarea.scrollTop = scroll_distance;
				}
			}
		}
	}
	set_map_style(map) {
		let mapstyle = new google.maps.StyledMapType([
			{
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#08274a"
					}
				]
			},
			{
				"featureType": "administrative",
				"elementType": "geometry",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "administrative.land_parcel",
				"elementType": "labels",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "landscape",
				"stylers": [
					{
						"color": "#ebeef3"
					}
				]
			},
			{
				"featureType": "poi",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "road.highway",
				"stylers": [
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "geometry.fill",
				"stylers": [
					{
						"color": "#d8e2d8"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "geometry.stroke",
				"stylers": [
					{
						"color": "#c8d6af"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "road.local",
				"elementType": "labels",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "transit",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "water",
				"stylers": [
					{
						"color": "#d6ebe7"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "geometry.stroke",
				"stylers": [
					{
						"color": "#40cdb9"
					}
				]
			}
		]);

		map.mapTypes.set('styled_map', mapstyle);
		map.setMapTypeId('styled_map');
		map.set("minZoom", this.min_zoom);
		map.set("maxZoom", this.max_zoom);
		map.set("streetViewControl", false);
		map.set("zoomControl", false);
		// map.set("zoomControlOptions",{
		// 	position: google.maps.ControlPosition.RIGHT_CENTER
		// });
	}

	set_search_term(search_term: string): void {
		this.search_term = search_term
	}

	markeraction(community, e: any) {
		let type = "unset";
		if (typeof e == "string") { type = e; }
		else {
			if (e.type) { type = e.type; }
			else if (e.va && e.va.type) { type = e.va.type; }
		}
		if (['click', 'dblclick'].indexOf(type) >= 0) {
			clearTimeout(this.timers.marker_action);
			this.timers.recent_action = true;
			this.timers.marker_action = setTimeout(() => { this.timers.recent_action = false; }, 1500);
		}
		this.selected_community = community;
		this.update_features();
	}

	update_features() {
		var showing = this.fs.sort_order.length;
		this.inst.data.setStyle((f) => {
			let id = f.getId();
			let c = this.fs.all_communities[id];
			let selected = this.selected_community ? this.selected_community.id == id : false;
			let zindex = (showing - c.sort_index) + 1;
			let icon = c.member ? '../../assets/icon-teal.png' : '../../assets/icon-navy-small.png';
			let label = null;
			if (selected) {
				zindex += showing;
				icon = c.member ? '../../assets/icon-green.png' : '../../assets/icon-green-small.png';
			}
			if (c.member) {
				label = {
					text: c.sort_index,
					fontSize: '1rem',
					color: 'white',
				};
			}
			let s = {
				label: label,
				title: c.name,
				visible: true,
				icon: icon,
				zIndex: zindex
			};

			if (this.fs.sort_order.indexOf(id) < 0) { s.visible = false; }
			let item = document.getElementById("community-" + id);
			if (item) {
				item.className = (item.className.split(" ").filter((cl) => { return cl != "selected" }).join(" "))
				if (selected) { item.className += " selected"; }
			}
			return s;
		});
		//this.trigger_resize();
		google.maps.event.trigger(this.inst, "resize");
	}

	set_autocomplete(element) {
		this.autocomplete_element = element;
	}
	init_autocomplete() {
		if (!this.autocomplete_element) { return false; }
		let autocomplete = new google.maps.places.Autocomplete(this.autocomplete_element, {
			types: ['address']
		});
		autocomplete.addListener('place_changed', () => {
			let place = autocomplete.getPlace();
			let filternav = this.ui.get("filternav");

			if (!place.geometry) {
				this.analytics.event('search', {
					search_term: place.name,
					autocomplete_source: 'geocode'
				});

				// this.map.latlng
				this.getlatlng(place.name).then((geo) => {
					if (geo['ok'] && geo['geometry'] && geo['geometry']['location']) {
						let coords = this.toLatLng(geo['geometry']['location']);
						this.set_center(coords);
						if (filternav) { filternav.close(); }
					}
				});
			}
			else {
				this.analytics.event('search', {
					search_term: place.name,
					autocomplete_source: 'places'
				});

				let coords = this.toLatLng(place.geometry.location);
				this.set_center(coords);
				if (filternav) { filternav.close(); }
			}
		});
	}

	toggle_map() {
		this.open != this.open;
	}
	trigger_resize() {
		let map_pos = this.s.get_map_settings();
		setTimeout(() => {
			google.maps.event.trigger(this.inst, "resize");
			this.set_center(map_pos.center);
		}, 0);
	}
	get_properties(): MapProps {
		if (!this.inst) { return null; }

		let bounds = this.inst.getBounds();
		let fbounds = this.toBounds(bounds);
		let props = {
			gbounds: bounds,
			bounds: fbounds,
			center: this.toLatLng(this.inst.getCenter()),
			zoom: this.inst.getZoom()
		};

		this.fs.map_props = props;
		this.fs.update_props();
		if (this.fs.map_props.scale > 0) {
			this.last_props = this.fs.map_props;
		}
		else {
			props = this.last_props;
			this.fs.map_props = props;
			this.fs.update_props();
		}
		return props;
	}
	getlatlng(address) {
		this.lookingUpAddress = true;
		return new Promise((resolve, error) => {
			this.api.getlatlng(address).subscribe((resp) => {
				if (resp && resp['status'] == "OK") {
					resolve(resp['results'][0]);
					this.lookingUpAddress = false;
				}
				else {
					error(resp);
					this.lookingUpAddress = false;
				}
			});
		});
	}
	toLatLng(coords): LatLng {
		if (typeof coords.lat == "function") {
			return {
				lat: coords.lat(),
				lng: coords.lng()
			};
		}
		else if (typeof coords.lat == "number") {
			return coords;
		}
	}
	toBounds(tbounds): Bounds {
		if (!tbounds) { return; }
		if (tbounds.toJSON) { tbounds = tbounds.toJSON(); }
		var bounds = { north: 0, south: 0, west: 0, east: 0 };
		if (typeof tbounds.north == "number") { bounds.north = tbounds.north; }
		if (typeof tbounds.south == "number") { bounds.south = tbounds.south; }
		if (typeof tbounds.west == "number") { bounds.west = tbounds.west; }
		if (typeof tbounds.east == "number") { bounds.east = tbounds.east; }
		return bounds;
	}
	get_center(): LatLng {
		if (this.inst) { return this.toLatLng(this.inst.getCenter()); }
		return null;
	}
	set_center(coords) {
		// move map
		if (this.inst) {
			this.inst.setCenter(coords);
		}
		// save in settings
		this.s.map_settings.center = coords;
		this.s.save_map_settings();
	}
	locateUser(): Promise<LatLng> {
		return new Promise(resolve => {
			navigator.geolocation.getCurrentPosition((position) => {
				this.inst.setZoom(this.zoomto_default);
				this.can_force_zoomout = true;
				this.userPos = {
					lat: position.coords.latitude,
					lng: position.coords.longitude
				};
				resolve(this.userPos);
			}, () => {
				this.ui.message("Location not found");
			});
		});
	}
	performSearch() {
		//If nothing is searched or if the searched term is the exact same as last time, reload page.
		if (!this.search_term || this.search_term == this.search_last) {
			this.updateCommunities(() => { });
		}
		else {
			this.ui.message("Locating " + (this.search_term.substr(0, 16)) + "...", '', 800);
			this.api.getlatlng(this.search_term).subscribe((res) => {
				if (this.zoomto_default) {
					this.inst.setZoom(this.zoomto_default);
					this.can_force_zoomout = true;
				}
				if (res['status'] === 'OK') { this.set_center(res['results'][0].geometry.location); }
				else {
					this.ui.message("Unable to find location.", '', 800);
				}
				this.search_last = this.search_term;
				this.updateCommunities(() => { });
			});
		}
		if (this.ui.get("mapsearch").nativeElement) {
			this.ui.get("mapsearch").nativeElement.value = "";
		}

	}
	updateCommunities(cb) {
		let props = this.get_properties();
		if (props) {
			this.ui.set("community_page", null);
			//let bounds = this.updatebounds(props.bounds);
			let bounds = props.gbounds;
			// have we updated the bounds? and need to ask for more communities
			if (this.update_communities) {
				if (this.request.basic_communities) {
					this.request.basic_communities.unsubscribe();
				}
				this.request.basic_communities = this.api.basic_communities(bounds, this.ids).subscribe((communities) => {
					// this.clearmarkers();
					// update community memory
					for (let id in communities) {
						let community = communities[id];
						if (!community || typeof community == "string") { continue; }
						// set some defaults, because if we do not it will break sorting!
						if (!community.member) { community.member = 0; }
						if (!community.featured) { community.featured = 0; }

						if (!this.fs.all_communities[community.id]) {
							this.fs.all_communities[community.id] = community;
							this.ids.push(+id);
						}
					}

					this.fs.on_map = [];

					this.total_communities = 0;
					this.total_shown_communities = 0;
					// pick out the ones on the map
					for (let id in this.fs.all_communities) {
						this.total_communities++;
						let com = this.fs.all_communities[id];
						if (props.gbounds.contains(com.geo)) {
							this.fs.on_map.push(+id);
							this.total_shown_communities++;
						}
					}

					// sort the communities based on filters
					if (this.fs.filters) {
						this.fs.sort_communities();
					}
					// filter out all the markers we already have
					let get_locations = this.fs.sort_order.filter((i) => { return this.featureids.indexOf(i) < 0; });

					// //	get community information we do not have
					if (this.fs.on_map && this.fs.on_map.length > 0) {
						this.api.get_community_info(get_locations).subscribe((communities) => {
							if (communities && !communities.error) {
								this.fs.add_communities(communities);
								//this.fs.sort_communities(); 
								//this.trigger_resize();
								google.maps.event.trigger(this.inst, "resize");
							}
						});
					}
					// load map features
					if (get_locations.length > 0) {
						this.api.geojson(get_locations).subscribe((j) => {
							if (j.features) {
								for (let i = 0, l = j.features.length; i < l; i++) {
									let f = j.features[i];
									if (this.featureids.indexOf(f.id) >= 0) {
										delete j.features[i];
									}
									else {
										f.visible = false;
										this.featureids.push(f.id);
										this.features.push(f);
										this.fs.all_communities[f.id].feature = f;
									}
								}
								this.inst.data.addGeoJson({ "type": "FeatureCollection", "features": this.features });
								this.update_features();
							}
						});
					}
					else {
						this.update_features();
					}

					// are there communities in bounds?	is this the first time we checked in a loop?
					if (this.total_shown_communities == 0 && this.can_force_zoomout) {
						console.log("No Communities!");
						// zoom out.. maybe we will find some?
						this.inst.setZoom(this.inst.getZoom() - 1);
						this.can_force_zoomout = false;
						console.log("canzoom", this.can_force_zoomout);
					}

					if (typeof cb == "function") { cb(); }
				},
					() => {
						console.log("Community Update: Error");
					},
					() => {
					});
				return this.request.community;
			}
		}
		else if (this.ui.get("community_page")) {
			let c = this.ui.get("community_page");
			c.score_data = this.fs.get_community_score(c);
			c.score = this.fs.calculate_score(c.score_data);
			this.ui.set("community_page", c);
		}
	}


	community_distance(community_id, round = 1) {
		let c = this.fs.all_communities[community_id];
		let d;
		if (c.score_data && c.score_data.distance) {
			d = c.score_data.distance.mile || 0;
		}
		d = d.toFixed(round);
		return d;
	}
	community_total_matches(community_id) {
		let m = this.community_matches_found(community_id);
		return m.length + " / " + this.fs.filters_count() + " matching filters";
	}
	community_matches_found(community_id) {
		let matches = [];
		let c = this.fs.all_communities[community_id];
		if (c.score_data) {
			for (const key in c.score_data) {
				if (c.score_data[key]) {
					matches = matches.concat(c.score_data[key].m);
				}
			}
		}
		matches = matches.filter(Boolean);
		return matches;
	}
	private updatebounds(b): Bounds {
		b = this.toBounds(b);
		if (!this.maxbounds) {
			this.maxbounds = b;
			this.update_communities = true;
		}
		else {
			if (b.north > this.maxbounds.north) { this.maxbounds.north = b.north; this.update_communities = true; }
			if (b.south < this.maxbounds.south) { this.maxbounds.south = b.south; this.update_communities = true; }
			if (b.west < this.maxbounds.west) { this.maxbounds.west = b.west; this.update_communities = true; }
			if (b.east > this.maxbounds.east) { this.maxbounds.east = b.east; this.update_communities = true; }
		}
		return this.maxbounds;
	}
	private mergecommunity(c1, c2) {
		for (let i in c2) {
			if (i == "data") { continue; }
			c1[i] = c2[i];
		}
		return c1;
	}

}
