<mat-toolbar class="sub-toolbar">
	Favorites	
</mat-toolbar>

<!-- <h3>{{favorites.length}} Favorites Set</h3>	 -->
<mat-card *ngFor="let id of favorites let i = index" (click)="showCommunity(id)">
	<ng-container *ngIf="communities[id]">
		<mat-card-header color="primary">
			<mat-card-title>
				<h3>{{communities[id].name}}</h3>
			</mat-card-title>
			<mat-card-subtitle>
				<address *ngIf="communities[id].address">{{communities[id].address.city}}</address>
			</mat-card-subtitle>
			<span class="layout-spacer"></span>
			<button mat-mini-fab (click)="showCommunity(id)">
					<mat-icon aria-label="Details">info</mat-icon>
			</button>
		</mat-card-header>
		<mat-card-content>
			
		</mat-card-content>
	</ng-container>
</mat-card>
