import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
// import { forEach } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { SortComponent } from '../sort/sort.component';
import { MapService } from '../../service/map.service';
import { FilterService } from '../../service/filter.service';
import { StorageService } from '../../service/storage.service';
import { SettingsService } from '../../service/settings.service';
import { ApiService } from '../../service/api.service';
import { AnalyticsService } from '../../service/analytics.service';
import { UiService } from '../../service/ui.service';
import { LatLng } from '../../definitions';
import { TourModule } from '../../module/tour/tour.module';

declare var google: any;

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
	providers: [],
})
export class SearchComponent implements OnInit, OnDestroy {
	selected;
	update_delay: number;
	zoom: number;
	center: LatLng;
	active: boolean;
	showfilter: boolean;
	center_user: boolean;
	markerinfo: ElementRef;
	resultsarea: ElementRef;
	move_stop_time;
	loading: boolean;
	search_str: string;
	@ViewChild('markerinfo') set setmarkerinfo(setmarkerinfo: ElementRef) {
		if (setmarkerinfo) {
			this.markerinfo = setmarkerinfo;
			this.ui.set("markerinfo", this.markerinfo);
		}
	}
	@ViewChild('resultsarea', { static: true }) set setresultsarea(setresultsarea: ElementRef) {
		if (setresultsarea) {
			this.resultsarea = setresultsarea;
			this.ui.set("resultsarea", this.resultsarea);
		}
	}
	@ViewChild('agmmaparea', { static: true }) set setagmmaparea(setagmmaparea: ElementRef) {
		if (setagmmaparea) {
			this.ui.set("agmmaparea", setagmmaparea);
		}
	}

	constructor(
		public fs: FilterService,
		public map: MapService,
		private router: Router,
		private route: ActivatedRoute,
		public dialog: MatDialog,
		public s: SettingsService,
		private store: StorageService,
		public api: ApiService,
		public ui: UiService,
		public analytics: AnalyticsService,
		private tour: TourModule,
	) { }

	ngOnInit() {
		this.center_user = true;
		this.active = false;
		this.showfilter = false;
		this.update_delay = 100;
		this.zoom = 14;

		this.center = {
			lat: 44.977565,
			lng: -93.2654394
		};

		if (this.route.snapshot.paramMap.get("searchstr")) {
			this.search_str = decodeURI(this.route.snapshot.paramMap.get("searchstr"));
			this.map.set_search_term(this.search_str);
			this.map.performSearch();
		}

		this.updatemap();
		let filternav = this.ui.get('filternav');
		//		this.s.load_settings();
		this.fs.result_count = +this.s.setting('show_total') || 50;
		// if(filternav)
		// {
		// 	filternav.onOpen.subscribe(() => {

		// 	});
		// }
		setTimeout(() => {
			if (this.fs.map_props.center) {
				this.center_user = false;
				this.center = this.fs.map_props.center;
			}
			this.selected = this.fs.selected;

		}, 0);
	}

	ngOnDestroy() {
		if (this.ui.get('filterMessage')) {
			this.ui.get('filterMessage').dismiss();
		}
	}

	filtersSelected() {
		return this.fs.filters_count();
	}

	toggle_filter() {
		let filternav = this.ui.get('filternav');
		if (filternav) {
			filternav.toggle();
		}
	}
	open_filter() {
		let filternav = this.ui.get('filternav');
		if (filternav) {
			filternav.open();
		}
	}
	results() {
		let res = this.fs.sort_order;
		if (res instanceof Array && res.length > 0) {
			let op = '';
			op += res.length + ' out of ';
			op += this.map.total_shown_communities;

			return op;
		}
		return "no";
	}
	more_results() {
		let res = this.fs.sort_order;
		if (res instanceof Array && res.length > 0) { return this.map.total_shown_communities > res.length; }
		return false;
	}
	less_results() {
		let min_total = +this.s.setting('show_total') || 50;
		let res = this.fs.sort_order;
		if (res instanceof Array && res.length > 0) { return min_total < res.length; }
		return false;
	}
	add_more() {
		if (this.active) { return false; }
		this.active = true;
		let min_total = +this.s.setting('show_total') || 50;
		this.fs.result_count += min_total;
		this.updatemap();
	}
	remove_more() {
		if (this.active) { return false; }
		this.active = true;
		let min_total = +this.s.setting('show_total') || 50;
		this.fs.result_count -= min_total;
		if (min_total < this.fs.result_count) { this.fs.result_count = min_total; }
		this.updatemap();
	}

	mapready(map) {
		this.map.init(map);

		// if (this.active && this.store.get('filterPrompt') === null) {

		//     this.store.set('filterPrompt',true);
		// } else if(this.filtersSelected()==0) {
		//     let filterMessage = this.ui.message("Add a filter for better results.", "Add Now");
		//     filterMessage.onAction().subscribe(() => {
		//         this.open_filter();
		//     });
		//     this.ui.set('filterMessage',filterMessage);
		// }

	}

	atminzoom() { return this.map.inst ? this.map.inst.getZoom() == this.map.max_zoom : true; }
	atmaxzoom() { return this.map.inst ? this.map.inst.getZoom() == this.map.min_zoom : true; }
	zoomin() {
		this.map.inst.setZoom(this.map.inst.getZoom() + 1);
	}
	zoomout() {
		this.map.inst.setZoom(this.map.inst.getZoom() - 1);
	}
	centeruser(reset = false) {
		this.active = true;
		this.map.locateUser().then((pos) => {
			this.map.set_center(pos);
			this.active = false;
		});
	}

	showcommunity(community) {
		this.analytics.event('select_content', {
			content_type: 'view community',
			value: community.id,
		});
		this.router.navigate(['/community/' + community.id]);
	}
	selectcommunity(community, e) {
		if (community.feature) {
			this.map.markeraction(community, e);
			if (e.type == 'click') {
				// this.ui.toggle_map();
				this.ui.open_map();
				this.map.trigger_resize();
			}
		}
	}
	updatemap() {
		clearTimeout(this.move_stop_time);
		this.move_stop_time = setTimeout(() => {
			if (this.map && this.map.inst) {
				let props = this.map.get_properties();
				this.zoom = props.zoom;
				this.center = props.center;
				this.s.set_map_settings({
					center: props.center,
					zoom: props.zoom
				});
			}
			this.active = true;
			this.map.updateCommunities(() => {
				this.active = false;
				document.getElementById("properties").scrollTo(0, 0);
			});
		}, this.update_delay);
	}
	community_type(id) {
		let c = this.fs.all_communities[id];
		let type = 'listed';
		if (this.ismember(c)) { type = (c.featured ? 'featured' : 'member'); }
		return type;
	}
	ismember(c) {
		return c ? (c.member || false) : false;
	}



	format_match(id) {
		if (this.fs.all_communities[id].score) {
			let score = this.fs.all_communities[id].score;
			score = score.toLocaleString(2);
			return Math.round(score) + "% match";
		}
		else {
			return "0% match";
		}
	}

	com_initials(id) {
		if (this.fs.all_communities[id].name) {
			let comname = this.fs.all_communities[id].name;
			var firstChar = this.fs.all_communities[id].name.charAt(0);

			if (firstChar <= '9' && firstChar >= '0') {

				// test start

				var ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
				var tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
				var teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];

				function convert_millions(num) {
					if (num >= 1000000) {
						return convert_millions(Math.floor(num / 1000000)) + " million " + convert_thousands(num % 1000000);
					} else {
						return convert_thousands(num);
					}
				}

				function convert_thousands(num) {
					if (num >= 1000) {
						return convert_hundreds(Math.floor(num / 1000)) + " thousand " + convert_hundreds(num % 1000);
					} else {
						return convert_hundreds(num);
					}
				}

				function convert_hundreds(num) {
					if (num > 99) {
						return ones[Math.floor(num / 100)] + " hundred " + convert_tens(num % 100);
					} else {
						return convert_tens(num);
					}
				}

				function convert_tens(num) {
					if (num < 10) return ones[num];
					else if (num >= 10 && num < 20) return teens[num - 10];
					else {
						return tens[Math.floor(num / 10)] + " " + ones[num % 10];
					}
				}

				function convert(num) {
					if (num == 0) return "zero";
					else return convert_millions(num);
				}

				return convert(firstChar);

				// test end
			}
			else {
				return comname;
			}
		}
	}



	format_budget(budget) {
		let op = '';
		if (budget && budget.length > 1 && budget[1] > 0) {
			op += "$" + budget[0].toLocaleString();
			if (budget.length > 1) {
				op += " - ";
				op += "$" + budget[1].toLocaleString();
			}
			else {
				op += " +";
			}
		}
		return op;

	}
	mapclick(event) {
	}
	toggleactive() {
		this.active = !this.active;
	}
	togglefilter() {
		this.showfilter = !this.showfilter;
		// update communities after filter change
		if (!this.showfilter) {
			this.updatemap();
		}
	}

	setting_change(e, name) {
		this.s.changed(e, name);
		this.updatemap();
	}

	openSort() {
		let dialogRef = this.dialog.open(SortComponent, {
			width: '250px',
			// height: '500px',
		});
	}
	selected_sort() {
		let val = this.s.settings.sort.value;
		let options = this.s.settings.sort.options;
		if (options[val]) {
			return options[val];
		}
		return "";
	}
	toggle_map() {
		this.ui.toggle_map();
		if (this.ui.mapstate) {
			// if the map is open trigger a resize;
			this.map.trigger_resize();
		}
	}

	cardClick(event, id) {
		if (event.target.localName != "mat-icon") {
			this.showcommunity(this.fs.all_communities[id]);
		}
	}

	image_orientation(com) {
		var ret = 'none';

		if (com.hasOwnProperty('banner')) {
			if (com['banner'].hasOwnProperty('lo_res')) {
				let src = com['banner']['lo_res'];
				var orientation,
					img = new Image();
				img.src = src;
				if (img.naturalWidth > img.naturalHeight) {
					ret = 'landscape';
				} else if (img.naturalWidth < img.naturalHeight) {
					ret = 'portrait';
				} else if (img.naturalWidth == img.naturalHeight) {
					ret = 'even';
				}

			}

		}

		return ret;
	}

	thumb_orientation(com) {
		var ret = 'none';

		if (com.hasOwnProperty('thumbnail')) {
			if (com['thumbnail'].hasOwnProperty('lo_res')) {
				let src = com['thumbnail']['lo_res'];
				var orientation,
					img = new Image();
				img.src = src;
				if (img.naturalWidth > img.naturalHeight) {
					ret = 'landscape';
				} else if (img.naturalWidth < img.naturalHeight) {
					ret = 'portrait';
				} else if (img.naturalWidth == img.naturalHeight) {
					ret = 'even';
				}
			}
		}

		return ret;
	}

}


