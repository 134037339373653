import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

import { LightboxModule } from '../../module/lightbox/lightbox.module';
import { UiService } from '../../service/ui.service';


declare var $: any;
declare var jquery: any;

@Component({
  selector: 'remote-video',
  templateUrl: './remote-video.component.html',
  styleUrls: ['./remote-video.component.scss']
})
export class RemoteVideoComponent implements OnInit {

	public video_shown = false;
	private show_on_ready = false;
	private init_complete = false;
	public oembed;
	public video_url;
	public video_url_sanatized;
	public default_thumbnail = '';
	public default_title = 'Video Clip';
	public autoplay: boolean = true;

	@Input() url: string;
	@Input() output;

	@Input('lightBox') lightbox_attr: boolean;

	@Input()
	set show(show)
	{
		if (!this.init_complete && show)
		{
			this.show_on_ready = true;
			this.init_complete = true;
		}
	}

	@Input() hideThumb: boolean;

 	constructor(
		private sanitizer: DomSanitizer,
		private http : HttpClient,
		private lightbox: LightboxModule,
		private ui: UiService
	) {}

	ngOnInit() {
		this.get_oembed().then(() => {
			if (this.show_on_ready)
			{
				this.show_video();
			}
		}, (err) => {
			console.log(err);
		});
	}

	public show_video()
	{
		if (this.output)
		{
			// Use output iframe
			this.output.src = this.video_url.toString();
		}
		else if (!this.lightbox_attr)
		{
			this.video_shown = true;
		}
		else
		{
			var iframe = document.createElement('iframe');
			iframe.setAttribute('src', this.video_url.toString());
			$(iframe).css({
				border: 'none',
				width: '75vw',
				height: '42vw',
			});
			this.lightbox.open(iframe);
		}
	}

  	private get_oembed()
	{
		return new Promise((resolve, reject) => {
			try
			{
				if (this.oembed)
				{
					resolve(this.oembed);
					return;
				}

				var oembed_url: string = this.get_oembed_url(this.url);

				if (!oembed_url)
					{ throw new Error('No oembed url found.'); }

				this.http.get(oembed_url).subscribe((data) => {
					this.oembed = data;

					var match = this.oembed.html.match(/src=\"([\S]+)\"/);
					if (match.length >= 2)
					{
						this.video_url_sanatized = this.sanitizer.bypassSecurityTrustResourceUrl(match[1]);
						this.video_url = match[1];

						resolve(this.oembed);
					}
					else
					{
						reject('Video iframe url not found.');
					}
				});
			}
			catch(e)
			{
				reject(e.message);
			}
		});
	}

	private get_oembed_url(video_url)
	{
		var oembed_url: string = '';

		switch (this.get_provider(video_url))
		{
			case 'vimeo':
				var match = video_url.match(/vimeo.com[\S]*\/([0-9]+)/i);

				if (!match || match.length < 2)
					{ break; }

				var id = match[1];

				if (!id)
					{ break; }

				oembed_url = 'https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/'+id;
			break;
		}

		if (this.autoplay && !this.ui.ismobile())
		{
			oembed_url += '&autoplay=1&autopause=0';
		}

		return oembed_url;
	}

	private get_provider(video_url: string)
	{
		if (typeof video_url === 'string' && video_url.indexOf('vimeo.com') !== -1)
		{
			return 'vimeo';
		}

		return 'unknown';
	}

}
