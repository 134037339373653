<div *ngIf="plan" class="floorplan-component">
	<div class="prof-col prof-col-main col8">
		<mat-card class="header-card">
			<mat-card-header class='main-header'>
				<mat-card-title class="main-community-title"><h1>{{community.name}}</h1></mat-card-title>
				<mat-card-subtitle><p>{{plan.label}} - {{plan.description}}</p></mat-card-subtitle>
			</mat-card-header>
		</mat-card>
		<mat-card class="description" *ngIf="plan.content">
			<mat-card-header>
				<mat-card-title><h2>About this Floorplan</h2></mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<p id="plan-description">{{plan.content}}</p>
			</mat-card-content>
		</mat-card>
		<mat-card class="floorplan-image">
			<mat-card-header>
				<mat-card-title><h2>Floorplan Map</h2></mat-card-title>
			</mat-card-header>
			<!-- <ul>
				<li *ngFor="let image of plan.images; let i = index">
					<img src="{{image}}" width="100%" />
				</li>
			</ul> -->
			<slider>
				<!-- <slider-slide [imageUrl]="image" *ngFor="let image of plan.images; let i = index"></slider-slide> -->
				<slider-slide [lightBox]="!ui.ismobile()" [lightboxUrl]="image" [imageUrl]="image" *ngFor="let image of plan.images; let i = index"></slider-slide>
			</slider>

		</mat-card>
	</div>
	<div class="prof-col prof-col-aside col4">
		<mat-card class="price-card" *ngIf="ui.hasPrice(community) && plan.price > 0">
			<mat-card-header>
				<mat-card-title><h2>Price</h2></mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<h2 id="price">{{ui.format_price(plan.price)}}</h2>
				<mat-basic-chip matTooltip="Payments Accepted: {{community.payment}}" matTooltipPosition="left">
					<mat-icon aria-label="payment" id="left" color="white">payment</mat-icon>
				</mat-basic-chip>
			</mat-card-content>
		</mat-card>

		<mat-card class="virtual-tour" *ngIf="plan.tour_name">
				<mat-card-header>
					<mat-card-title><h2>3D Tours</h2></mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<a href="{{plan.tour_url}}">
						<mat-basic-chip class="has-tour" matTooltip="3D Tour Available">3D</mat-basic-chip>
						<h3 id="tour">
							{{plan.tour_name}}
						</h3>
					</a>
				</mat-card-content>
			</mat-card>

			<mat-card #contactcard id="contactcard" class="contact">
				<mat-card-header>
					<mat-card-title><h2>Contact Community</h2></mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<div class="address">
						<strong>{{community.name}}</strong>
						<a [href]="ui.mapLink(community)" target="_blank">
							<address>
								{{community.address.street}}<br />
								{{community.address.city}}
								{{community.address.state}}<br />
								{{community.address.zip}}
							</address>
						</a>
					</div>
					<div class="website" *ngIf="community.website && community.website!=''">
						<a href="{{community.website}}">Visit Website</a>
					</div>

					<mat-list *ngIf="community.contacts && community.contacts.length>0" class="contact-list">
						<mat-list-item class="contact" *ngFor="let contact of community.contacts; let i = index">
							<img *ngIf="contact.url != ''" matListAvatar src="{{contact.url}}" alt="Photo of {{contact.name}}" />
							<h4 mat-line>{{contact.name}}</h4>
							<p *ngIf="contact.title != ''" mat-line>{{contact.title}}</p>
						</mat-list-item>
					</mat-list>
					
					<!--email-->
					<button mat-raised-button color="primary" class="col2" (click)="email()" *ngIf="community.display.contact_community.email_button && community.email">
						<mat-icon aria-label="email" id="left" color="white">email</mat-icon>
						Email
					</button>

					<!--phone-->
					<button mat-raised-button color="primary" class="col2" (click)="phone()" *ngIf="community.display.contact_community.phone_button && community.phone">
						<mat-icon aria-label="phone" id="left" color="white">phone</mat-icon>
						Phone
					</button>
				</mat-card-content>
			</mat-card>
			</div>
</div>
