<div class="close-wrapper">
	<button mat-mini-fab type="button" (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
</div>
<ng-template [ngIf]="view=='form'">
	<h2 class="form-hdr" mat-dialog-title>
		This message will be sent directly to

		<ng-container *ngIf="community.display.community_rep.active && (community.contacts && community.contacts.length>0)">
			<span class="form-hdr-txt form-hdr-person" *ngFor="let contact of community.contacts | slice:0:community.display.community_rep.limit; let i = index">
				{{contact.name}} at
			</span>
		</ng-container>
	
		<span class="form-hdr-txt form-hdr-community">{{community.name}}</span>.
	</h2>
	<p class="error">{{ form_error }}</p>
	<mat-dialog-content>
		<form class="community-contact-form" name="community-contact-form" (submit)="submit()" [formGroup]="contact_form">
			<mat-form-field class="form-input">
				<!-- <mat-icon matPrefix>person</mat-icon> -->
				<input formControlName="name" type="text" name="name" matInput required>
				<mat-placeholder>Name</mat-placeholder>
				<mat-error>{{ get_error('name') }}</mat-error>
			</mat-form-field>
			<mat-form-field class="form-input">
				<!-- <mat-icon matPrefix>email</mat-icon> -->
				<input formControlName="email" type="text" name="email" matInput required>
				<mat-placeholder>Email</mat-placeholder>
				<mat-error>{{ get_error('email') }}</mat-error>
			</mat-form-field>
			<mat-form-field class="form-input">
				<!-- <mat-icon matPrefix>phone</mat-icon> -->
				<input formControlName="phone" type="text" name="phone" matInput>
				<mat-placeholder>Phone</mat-placeholder>
				<mat-error>{{ get_error('phone') }}</mat-error>
			</mat-form-field>
			<mat-form-field class="form-input form-textarea">
				<!-- <mat-icon matPrefix>message</mat-icon> -->
				<textarea formControlName="message" matInput></textarea>
				<mat-placeholder>Optional Message</mat-placeholder>
			</mat-form-field>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions>
			<!-- <button mat-button type="button" (click)="dialogRef.close()">Close</button>
			<button mat-button type="submit" (click)="submit()">Send</button> -->
			<button mat-raised-button color="primary" class="col2" type="button" (click)="dialogRef.close()">Close</button>
			<button mat-raised-button color="primary" class="col2" type="submit" (click)="submit()">Send</button>
	</mat-dialog-actions>
</ng-template>
<ng-template [ngIf]="view=='spinner'">
	<mat-dialog-content>
		<mat-progress-bar mode="indeterminate"></mat-progress-bar>
		<p>Loading</p>
	</mat-dialog-content>
</ng-template>
<ng-template [ngIf]="view=='success'">
	<h2 mat-dialog-title><mat-icon>check_circle</mat-icon> We have recieved your message!</h2>
	<p>Someone will be in contact with you soon.</p>
</ng-template>
