import { NgModule, Component, Inject, OnInit, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

declare var $: any;
declare var jquery: any;

@Component({
	styleUrls: ['./lightbox.component.scss'],
	templateUrl: './lightbox.component.html'
})
export class LightboxComponent implements OnInit {

	container: any;
	loaded: boolean = false;

	constructor(
		public dialogRef: MatDialogRef<LightboxComponent>,
    	@Inject(MAT_DIALOG_DATA) public data: any,
		private el: ElementRef
	)
	{
		this.container = $(el.nativeElement);
	}

	ngOnInit()
	{
		let content = this.data.content;

		switch (typeof content)
		{
			case 'string':
				let img = new Image();
				$(img).addClass('lightbox-image');
				$(img).attr('src', content);
				$(img).css({
					'max-width': '75vw',
					'max-height': '75vh'
				});
				$(img).load(() => {
					this.loaded = true;
					this.container.find('.lightbox-inner-container').append(img);
				});
			break;

			case 'object':
            	if (content instanceof Element)
				{
					this.loaded = true;
					this.container.find('.lightbox-inner-container').append(content);
				}
			break;
		}
	}

	close()
	{
		this.dialogRef.close();
	}
}

@NgModule({
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		MatProgressSpinnerModule
	],
	declarations: [ LightboxComponent ],
})
export class LightboxModule {
	constructor(public dialog: MatDialog) {}

	open(content)
	{
		if (typeof content === 'undefined')
		{
			console.error('Lightbox needs content');
			return;
		}

		let ref = this.dialog.open(LightboxComponent, {
			data: {
				content: content
			}
		});
	}
}
