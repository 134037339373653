import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

//import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

import { SettingsService } from '../../service/settings.service';
import { UiService } from '../../service/ui.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

	constructor(
		public s : SettingsService,
		private ui : UiService
	) { }

	ngOnInit()
	{
	}
}
