import { OnInit, AfterViewInit, NgModule, HostListener, Directive, Component, ElementRef, Input, ViewEncapsulation, Injector, ComponentFactoryResolver, ViewChildren, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { LightboxModule } from '../../module/lightbox/lightbox.module';

declare var window: any;
declare var $: any;
declare var jquery: any;

@Directive({
	selector: 'slider-slide'
}) export class SliderSlideDirective implements OnInit
{
	@Input() imageUrl: string;
	@Input() lightboxUrl: string;
	@Input() lightBox: boolean;
	container: any;
	inside_element: any;

	private show_count: number = 0;
	private init_done: boolean = false;

	constructor(
		private el: ElementRef,
		private _injector: Injector,
		private componentFactoryResolver: ComponentFactoryResolver,
		private lightbox: LightboxModule
	)
	{
		this.container = $(el.nativeElement);

		let ref = this;
		this.container.data('api', ref);

		this.container.click(() => {
			this.open_dialog();
		});

		// Add spinner
		// this.container.addClass('loading');
	}

	ngOnInit()
	{
		this.inside_element = this.container.children().first();

		if (this.inside_element.prop('tagName') === 'REMOTE-VIDEO')
		{
			this.show();
		}
	}

	init_image()
	{
		// this.container.addClass('loading');

		return new Promise((resolve, reject) => {
			if (this.init_done || !this.imageUrl)
				{ return resolve(); }
			this.init_done = true;

			let img = new Image();
			img.src = this.imageUrl;
			this.inside_element = $(img);
			this.inside_element.appendTo(this.container);
			this.inside_element.load(() => {
				this.show();
				return resolve();
			});
		});
	}

	cancel_load()
	{
		console.log('CANCEL LOAD');
		this.container.removeClass('loading');
	}

	open_dialog()
	{
		if (this.lightboxUrl)
		{
			this.lightbox.open(this.lightboxUrl);
		}
	}

	show()
	{
		this.show_count++; // to prevent runaway loop

		let ratio = 0;
		if (this.inside_element.prop('tagName') === 'REMOTE-VIDEO')
		{
			ratio = 0.56;
		}
		else
		{
			let w = this.inside_element.width();
			let h = this.inside_element.height();
			ratio = (w != 0 ? (Math.floor(100 * h / w) / 100) : 0);

			if (ratio == 0)
			{
				if (this.show_count > 10)
				{
					ratio = 0.5; // fallback
				}
				else
				{
					setTimeout(() => {
						this.show();
					}, 500);
					return;
				}
			}
		}

		this.container.attr('data-ratio', ratio.toString());
		this.inside_element.css('width', '100%');
		this.inside_element.css('height', '100%');

		if (this.imageUrl)
		{
			this.container.css('background-image', "url("+this.imageUrl+")");
			this.inside_element.remove();

			//new start

			if (ratio > 1) {
				this.container.addClass('slide-portrait');
			}

			//new end

		}



		this.container.removeClass('loading');
	}
}

@Component({
	selector: 'slider',
	templateUrl: 'slider.component.html',
	styleUrls: ['slider.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SliderComponent implements AfterViewInit {

	index: number = 0;
	total: number = 0;
	container: any;
	slides: any;
	progress: number = 0;
	loading: boolean = true;
	private first_show: boolean = false;
	private loop;

	constructor(private el: ElementRef)
	{
		this.container = $(el.nativeElement);
		window.onresize = (e) => {
			this.resize(e);
		};

		setInterval(() => {
			$(window).trigger('resize');
		}, 1000);
	}

	ngAfterViewInit()
	{
		this.loop = setInterval(() => {
			// this.slides = this.container.getElementsByTagName('slider-slide');
			this.slides = this.container.find('slider-slide');
			this.total = this.slides.length;
			// let loading = document.querySelectorAll("slider-slide.loading").length;
			let loading = this.container.find("slider-slide.loading").length;

			let old_progress = this.progress;
			this.progress = Math.ceil(100 * (this.slides.length - loading) / this.slides.length);

			if (old_progress !== this.progress)
			{
				this.container.addClass('reveal');
				// this.addClass(this.container, 'reveal');
			}

			if (this.progress >= 100)
			{
				clearInterval(this.loop);

				setTimeout(() => {
					this.loading = false;
					this.show_slide(0);
				}, 400);
			}
		}, 500);
	}

	resize(e)
	{
		this.show_slide(this.index);
	}

	next_slide()
	{
		let index = this.get_next(this.index);
		this.show_slide(index);
		this.index = index;
	}
	prev_slide()
	{
		let index = this.get_prev(this.index);
		this.show_slide(index);
		this.index = index;
	}

	show_slide(index: number)
	{
		if (!this.slides)
			{ return; }

		// Adjust container height
		let ratio = parseFloat(this.slides.eq(index).attr('data-ratio'));
		this.container.css('height', Math.ceil(ratio * this.container.width()) + 'px');

		var len = this.slides.length;
		var shown = 1;
		for (let i = 0, l = index, r = index, moved = 0; moved < len; i++, l = (l > 0 ? l-1: len-1), r = (r < len-1 ? r+1 : 0))
		{
			var percent: string;
			var slide: any;

			// Slides hidden left
			if (this.slides[l])
			{
				slide = this.slides.eq(l);

				percent = (-100 * i) + '%';
				slide.css('transform', 'translate3d('+ percent + ',0,0)');

				if (i >= shown)
				{
					// slide.css('display', 'none');
					slide.css('opacity', '0');					
				}
				else
				{
					slide.css('opacity', '1');
					slide.data('api').init_image();
				}

				moved++;
			}

			// Slides hidden right
			if (moved < len && r!=l && this.slides[r])
			{
				slide = this.slides.eq(r);

				percent = (100 * i) + '%';
				slide.css('transform', 'translate3d('+ percent + ',0,0)');

				if (i >= shown)
				{
					slide.css('opacity', '0');
				}
				else
				{
					slide.css('opacity', '1');
					slide.data('api').init_image();
				}

				moved++;
			}
		}

		this.first_show = true;
	}

	show_controls()
	{
		return !this.loading && this.slides.length > 1;
	}

	get_next(index)
	{
		return (index < this.slides.length - 1 ? index + 1 : 0);
	}
	get_prev(index)
	{
		return (index > 0 ? index - 1 : this.slides.length - 1);
	}

	// private addClass(element: Element, class_title: string)
	// {
	// 	var className = element.className;
	// 	className = (!className || className=='undefined' ? '' : className);
	// 	if (className.indexOf(class_title) !== -1)
	// 		{ return; }
	// 	className = (className + ' ' + class_title).replace(/[\s]+/g, ' ').trim();
    //
	// 	element.className = className;
	// }
	// private removeClass(element: Element, class_title: string)
	// {
	// 	var className = element.className;
	// 	className = (!className || className=='undefined' ? '' : className);
	// 	if (!className || !class_title || className.indexOf(class_title) === -1)
	// 		{ return; }
	// 	className = className.replace(new RegExp(class_title, 'g'), '').replace(/[\s]+/g, ' ').trim();
    //
	// 	element.className = className;
	// }
}

@NgModule({
  declarations:	[ SliderComponent  , SliderSlideDirective  ],
  exports:		[ SliderComponent  , SliderSlideDirective  ],
  imports:		[ CommonModule, MatIconModule, MatProgressSpinnerModule ],
  bootstrap:	[ SliderComponent ]
})
export class SliderModule {}
