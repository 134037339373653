import { Injectable } 				from '@angular/core';
import { SettingsService } 			from '../service/settings.service';
import { StorageService } 			from '../service/storage.service';
import { AccessService } 			from '../service/access.service';

@Injectable()
export class UserdataService {
	data : any = {};

  	constructor(
		public s : SettingsService,
		public access : AccessService,
		public store : StorageService
	) {
		this.s.$settings.subscribe((data) => {
			this.init();
		});

	}
	init()
	{
		this.data = {};
		// autologin user

		// pull local data
		this.getdata();

		// sync userdata

	}


	getdata()
	{
		this.data = this.store.get("userdata")||{};
		return this.data;
	}
	setdata(key,value)
	{
		if(!key)
			{ return false; }
		if(typeof key == "object")
		{
			for(const k in key)
			{
				if (key.hasOwnProperty(k))
					{ this.data[k] = key[k]; }
			}
		}
		else
			{ this.data[key] = value; }
		return this.savedata();
	}
	savedata()
		{ return this.store.set("userdata",this.data); }



	favorites()
	{
		var f = this.data.favorites||[];
		
		return f;
	}
	addfavorite(community_id)
	{
		if(this.hasfavorite(community_id) === false)
			{ this.data.favorites.push(community_id); }
		this.savedata();
	}
	hasfavorite(community_id)
	{
		if(typeof this.data.favorites != "object")
			{ this.data.favorites = []; }
		var index = this.data.favorites.indexOf(community_id);
		return index >= 0 ? index : false;
	}
	removefavorite(community_id)
	{
		var index = this.hasfavorite(community_id);
		if(index !== false)
			{ this.data.favorites.splice(index,1); }
		console.log(index);
		this.savedata();
	}

}
