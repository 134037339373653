import { Component, OnInit, ElementRef, Renderer2, ViewChild }	from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } 				from '@angular/router';
import { Location } 											from '@angular/common';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressSpinnerModule } 							from '@angular/material/progress-spinner';
import { MatTooltipModule } 									from '@angular/material/tooltip';


import { MapService } 						from './service/map.service';
import { FilterService } 					from './service/filter.service';
import { AccessService } 					from './service/access.service';
import { UiService } 						from './service/ui.service';
import { ApiService } 						from './service/api.service';
import { AnalyticsService } 				from './service/analytics.service';
import { SettingsService } 					from './service/settings.service';
import { UserdataService } 					from './service/userdata.service';

import { AccessComponent } 					from './component/access/access.component';
import { link } 							from './definitions';

declare var window: any;
declare var google: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [ MatSidenav ],
})
export class AppComponent implements OnInit
{
	title 	= 'Senior Communities Guide';
	theme 	= 'my-theme';
	path	: string;
	page	: string;
    nav 	: link[];
    searching : boolean;
	toolbaricon	: string;
	public sidenav: MatSidenav;
	public filternav: MatSidenav;
	mapsearch : ElementRef;
	@ViewChild('sidenav') 			set setsidenav(setsidenav: MatSidenav)		{
		if(setsidenav)
		{
			this.sidenav = setsidenav;
			this.ui.set("sidenav",this.sidenav);
		}
	}
	@ViewChild('appcontent') 			set setappcontent(setappcontent: ElementRef)		{
		if(setappcontent)
		{
			this.ui.set("appcontent",setappcontent);
		}
	}
	@ViewChild('sidenavContainer') 		set setsidenavContainer(setsidenavContainer: MatSidenav)		{
		if(setsidenavContainer)
		{
			this.ui.set("sidenavContainer",setsidenavContainer);
		}
	}
	@ViewChild('filternav') 		set setfilternav(setfilternav: MatSidenav)	{
		if(setfilternav)
		{
			this.filternav = setfilternav;
			this.ui.set("filternav",this.filternav);
        }
	}

	@ViewChild('mapsearch')
	set setmapsearch(setmapsearch: ElementRef)	{
        console.log("Set Map Search",setmapsearch);
		if(setmapsearch)
		{
			this.mapsearch = setmapsearch;
			this.ui.set("mapsearch",this.mapsearch);
		}
	}

	constructor(
		private router: Router,
		public map : MapService,
		public fs : FilterService,
		public s : SettingsService,
		public ui : UiService,
		public api : ApiService,
		public access : AccessService,
		public userdata : UserdataService,
		public dialog: MatDialog,
		private renderer: Renderer2,
        private analytics: AnalyticsService,
	)
	{
        this.searching = true;
		this.toolbaricon = "menu";
		this.router.events.subscribe((event:any) => {
			if(event.url)
			{
				this.page = event.url.split("/")[1];
				this.update_icon();
			}
			if (event instanceof NavigationEnd)
			{
				this.analytics.page_view(event.urlAfterRedirects);
			}
			this.ui.resize_loop();

		});
		this.nav = [
//			{ label : "Change Filter",		link : "/filter",	icon : "filter_list" },
			// { label : "Community Search",	link : "/search",	icon : "search" },
//			{ label : "Account",			link : "",			icon : "account_box" },
			{ label : "Settings",			link : "/settings",		icon : "settings" },
			{ label : "Favorites",			link : "/favorites",	icon : "favorite" }
		];
		this.s.$settings.subscribe((data) => {
			// is this the users first time here?
		});
	}
    ngOnInit()
    {
    }
    scroll(e)
	{
		console.log("scroll",e);
    }
    onSearchPage() : boolean
    {
        return this.router.url.split('/')[1] == 'search';
    }
    searchButton() : boolean
    {
        if (this.searching) {
            this.searching = false;
        } else {
            this.searching = true;
        }
        this.mapsearch.nativeElement.value = "";
        if (this.searching) {
            return this.searching;
        }
        return this.searching;
    }

	search_click()
	{
		this.filternav.open();
	}
	search_change(value)
	{
        this.map.search_term = value;
	}
    run_search(url_param)
    {
		if(url_param){this.map.set_search_term(url_param)}
	   
		if (this.map.search_term != '') {
            if (this.router.url != "/search/") {
                this.router.navigate(["/search/"]);
			}
            this.map.performSearch();
        }
		this.mapsearch.nativeElement.blur();
		this.searching = true;
    }
	
	change_page(page_path) {

		this.sidenav.close();
		this.router.navigate([page_path]);
		this.page = page_path;
		this.update_icon();
    }
    
	loggedin()
	{
		return (this.access.user_data!=null);
	}

	access_dialog(): void
	{
		this.sidenav.close().then(() => {
			let dialogRef = this.dialog.open(AccessComponent, {
				width: '250px',
				// height: '500px',
			});

			dialogRef.afterClosed().subscribe((result) => {
			});
		});
	}

	open_filter()
	{
		let filternav 		= this.ui.get('filternav');
		if(filternav)
		{
			filternav.open();
		}
	}

	closefilters() {
		this.ui.get("filternav").close();
		this.map.performSearch();
	}

	clearFilters(){
		for (const key in this.fs.selected) {
			if (this.fs.selected.hasOwnProperty(key)) {
				this.fs.selected[key]={};

			}
		}
		this.fs.save_filters();
	}


	wholenumber(p){
		return Math.round(p);
	}

	update_icon()
	{
		switch (this.page)
		{
			case '':
			case 'search':
				this.toolbaricon = "menu";
			break;

			default:
				this.toolbaricon = "chevron_left";
			break;
		}
	}

	click_go_back(){
		switch (this.page)
		{
			case '':
			case 'search':
				this.sidenav.toggle();
			break;

			default:
				this.ui.backClicked();
			break;
		}
	}
}

