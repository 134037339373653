import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
// import { Http,Response } from '@angular/http';
import { StorageService } from './storage.service';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class SettingsService {
	config			: any;
	saved			: any;
	settings		: any;
	map_settings	: any;
	ready 			: boolean;
	$init			: Observable<any>;
	$settings		: Subject<any>;
	constructor(private store : StorageService,private http: HttpClient,)
	{
		this.ready 			= false;
		this.saved			= {};
		this.map_settings	= {};

		this.$init 			= this.get_config();
		this.$settings		= new Subject();

		this.$settings.subscribe((data) => {
			this.init(data);
		});

		this.$init.subscribe(this.$settings);
	};
	init(data)
	{
		this.config = data;
		if(this.config.settings_options)
		{
			this.settings = data.settings_options;
			this.load_settings();
			this.ready = true;
		}

	}
	get_config()
	{
		return this.http.get("/assets/app.config.json");
			// .map((res:Response) => res.json());
	}
	changed(e,name)
	{
		if (!this.settings.hasOwnProperty(name))
			{ this.settings[name] = {}; }
		this.settings[name].value = e;
		this.saved[name] = e;
		console.log(name,e,this.saved[name]);
		this.save();
	}
	button_clicked(e,name)
	{
		if(typeof this["button_event_"+name] == "function")
		{
			console.log(e);
			this["button_event_"+name]().then((r) => {
				console.log(r);
			});
		}
		return false;
	}
	button_event_clear_cache()
	{
		return new Promise((resolve,reject) => {
			this.$init.subscribe((data) => {
				this.store.set("stg_settings",{});
				this.saved = {};
				this.settings = data.settings_options;
				this.load_settings();
				resolve(this.settings);
			});
		});
	}
	load_settings()
	{
		this.saved = this.get_settings();
		for (const key in this.settings) {
			if (this.settings.hasOwnProperty(key))
			{
				if(this.saved[key])
					{ this.settings[key].value = this.saved[key]; }
				else if(this.settings[key].value == null)
				{
					if(this.settings[key].options)
						{ this.settings[key].value = Object.keys(this.settings[key].options)[0]; }
				}
			}
		}
	}
	save()
	{
		for (const key in this.settings) {
			if (this.settings.hasOwnProperty(key))
			{
				this.saved[key] = this.settings[key].value;
			}
		}
		this.store.set("stg_settings",this.saved);
		this.save_map_settings();
	}
	setting(name)
	{
		return (this.saved[name] || (typeof this.settings[name] === 'object' && this.settings[name].hasOwnProperty('value') ? this.settings[name].value : false));
	}
	get_settings()
	{

		this.saved = this.store.get("stg_settings")||{};
		this.map_settings = this.store.get("map_settings")||{};
		if(typeof this.saved != "object")
			{  this.saved = {}; }
		if(typeof this.map_settings != "object")
			{  this.map_settings = {}; }

		return this.saved;
	}
	set_map_settings(mapsettings : any)
	{
		if(typeof mapsettings == "object")
			{ this.map_settings = mapsettings; }
		this.save_map_settings();
	}
	get_map_settings()
	{
		this.map_settings = this.store.get("map_settings")||{};
		return this.map_settings;
	}
	save_map_settings()
		{ this.store.set("map_settings",this.map_settings); }
	keys(obj)
	{
		return Object.keys(obj);
	}
}
