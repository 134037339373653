<ng-container *ngIf="!community">
    <mat-progress-spinner mode="indeterminate" color="primary" class="loading-spinner"></mat-progress-spinner>
</ng-container>
<div *ngIf="community" class="profile-community profile-{{community.tier_name | lowercase}}" [ngClass]="{'profile-partner': community.tier > 1}">

    <div class="profile-banner" *ngIf="community.tier == 0 || community.tier == 1">&nbsp;</div>

    <div class="profile-banner" *ngIf="community.tier > 1">SeniorCommunities.Guide Partner</div>

    <mat-card class="header-card">
        <mat-card-header class="main-header">
            <mat-card-title class="main-community-title">
                <h1>{{community.name}}</h1>
            </mat-card-title>
            <mat-card-subtitle>
                <a [href]="ui.mapLink(community)" target="_blank">
                    <address>
						{{community.address.street}}, {{community.address.city}}, 
						{{community.address.state}} {{community.address.postal}}
					</address>
                </a>
            </mat-card-subtitle>
            <span class="layout-spacer"></span>
            <button *ngIf="community.display.favorite.active" mat-icon-button (click)="toggle_favorite()" class="favorite-button">
				<mat-icon class="layout-icon" color="primary" >{{favorite_icon()}}</mat-icon>
			</button>
            <div *ngIf="community.tier == 0" class="cta-link">
                <div class="cta-inner">
                    <a href="https://seniorcommunities.guide/partners/sign-up/" target="_blank">
                        <span class="cta-label">Claim This Profile
					</span>
                        <span class="cta-txt">Are you a representative of this community? Claim your <span>profile</span> today!</span>
                    </a>
                </div>
            </div>
        </mat-card-header>
    </mat-card>


    <div class="prof-col prof-col-main prof-col-slider col8">

        <span *ngIf="community.display.slideshow.active == false" class="layout-spacer"></span>

        <mat-card class="carousel-card" *ngIf="community.display.slideshow.active && community.gallery.length > 0">
            <slider>
                <ng-container *ngIf="community.display.slideshow.video_allowed">
                    <slider-slide *ngFor="let video of community.misc.video_walkthrough">
                        <remote-video [lightBox]="!ui.ismobile()" (click)="analytics_action('video_thumb_click')" [url]="video.value" [show]="ui.ismobile()"></remote-video>
                    </slider-slide>
                </ng-container>
                <ng-container *ngIf="community.gallery.length > 0">
                    <slider-slide [lightBox]="!ui.ismobile()" [imageUrl]="item.lo_res" [lightboxUrl]="!ui.ismobile() ? item.hi_res : false" *ngFor='let item of community.gallery | slice:0:community.display.slideshow.limit'></slider-slide>
                </ng-container>
            </slider>
        </mat-card>

    </div>


    <div class="prof-col prof-col-aside col4">

        <mat-card #contactcard id="contactcard" class="contact">
            <mat-card-header>
                <mat-card-title>
                    <h2>Contact Community</h2>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <!-- Address -->
                <div class="address">
                    <strong>{{community.name}}</strong>
                    <a [href]="ui.mapLink(community)" target="_blank">
                        <address>
						{{community.address.street}}<br />
						{{community.address.city}},	{{community.address.state}} {{community.address.postal}}
					</address>
                    </a>
                </div>

                <!-- People -->
                <mat-list *ngIf="community.display.community_rep.active && (community.contacts && community.contacts.length>0)" class="contact-list">
                    <mat-list-item class="contact" *ngFor="let contact of community.contacts | slice:0:community.display.community_rep.limit; let i = index">
                        <img *ngIf="contact.url != ''" matListAvatar src="{{contact.url}}" alt="Photo of {{contact.name}}" />
                        <h4 mat-line>{{contact.name}}</h4>
                        <p *ngIf="contact.title != ''" mat-line>{{contact.title}}</p>
                    </mat-list-item>
                </mat-list>

                <!-- Website -->
                <!-- 
				<div class="website" *ngIf="community.display.contact_community.website_link && (community.website && community.website!='')">
				<a href="{{community.website}}" target="_blank">Visit Website</a>
			</div> -->

                <!-- Email -->
                <button mat-raised-button color="primary" class="col2" (click)="email()" *ngIf="community.display.contact_community.email_button && community.email">
				<mat-icon aria-label="email" id="left" color="white">email</mat-icon>
				Email
			</button>

                <!-- Phone -->
                <button mat-raised-button color="primary" class="col2" (click)="phone()" *ngIf="community.display.contact_community.phone_button && community.phone">
				<mat-icon aria-label="phone" id="left" color="white">phone</mat-icon>
				Phone
			</button>
            </mat-card-content>
        </mat-card>

        <mat-card *ngIf="ui.hasPrice(community)" class="price-card">
            <mat-card-header>
                <mat-card-title>
                    <h2>Price</h2>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <h2 id="price">{{ui.priceRange(community)}}</h2>
                <div *ngIf="community.payment">
                    <p>
                        <strong>Payments Accepted:</strong>
                        <mat-basic-chip>
                            <mat-icon aria-label="payment" color="white">payment</mat-icon>
                        </mat-basic-chip>
                        {{community.payment.join(", ")}}
                    </p>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="match-card" *ngIf="community.score > 0 && fs.filters_count()">
            <mat-card-header>
                <h2>Your Match</h2>
            </mat-card-header>
            <div class="filter-matches">
                <div class="match-percentage-container">
                    <span class="match-spinner">
					<mat-progress-spinner mode="determinate" [value]="wholenumber(community.score)">

					</mat-progress-spinner>
					<p id="match-percentage">{{wholenumber(community.score)}}%</p>
					</span>
                </div>

            </div>

        </mat-card>

        <mat-card class="how-match" *ngIf="filter_matches.length>0">
            <mat-card-header>
                <h2>How do you match?</h2>
            </mat-card-header>
            <mat-card-content>
                <div class="how-match">
                    <ng-container>
                        <mat-chip-list *ngFor="let item of filter_matches">
                            <mat-chip>{{item}}</mat-chip>
                        </mat-chip-list>
                    </ng-container>
                </div>
            </mat-card-content>
        </mat-card>

    </div>


    <div class="prof-col prof-col-main prof-col-details col8">

        <mat-card *ngIf="community.display.description.active && has(community.description, 'string')">
            <mat-card-header>
                <mat-card-title>
                    <h2>Our Community</h2>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p>{{community.description}}</p>
            </mat-card-content>
        </mat-card>

        <mat-card *ngIf="community.display.pet_policy.active && (community.pets || community.content.policies.types.pets)">
            <mat-card-header>
                <mat-card-title>
                    <h2>Pet Policy</h2>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>

                <div *ngIf="community.pets" class="pets-allowed how-match">
                    <mat-chip-list *ngFor="let item of community.pets">
                        <mat-chip>{{item}}</mat-chip>
                    </mat-chip-list>
                </div>

                <ng-container *ngIf="community.content.policies.types.pets">
                    <p *ngFor="let pet of community.content.policies.types.pets">
                        {{ pet.value }}
                    </p>
                </ng-container>

            </mat-card-content>
        </mat-card>

        <mat-card class="location-card">
            <mat-card-header>
                <mat-card-title>
                    <h2>Location</h2>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="map">
                    <agm-map id="communitymap" #communitymap [latitude]="community.address.geo.lat" [longitude]="community.address.geo.lng" [zoom]="zoom" (mapReady)="mapready($event)" scrollwheel></agm-map>
                </div>
                <div class="address">
                    <a [href]="ui.mapLink(community)" target="_blank">
                        <address>
						{{community.address.street}}<br />
						{{community.address.city}},	{{community.address.state}} {{community.address.postal}}
					</address>
                    </a>
                </div>


                <div class="directions">
                    <a mat-stroked-button [href]="ui.mapDirLink(community)" class="btn-icon btn-icon-directions" target="_blank" aria-label="Directions to community">
                        <span class="icon-wrap">
						<mat-icon class="mat-icon-email">directions</mat-icon>
					</span>
                        <span>Map It</span>
                    </a>
                </div>


            </mat-card-content>
        </mat-card>
        
        <mat-card *ngIf="community.display.floorplans.active && community.floorplans.length > 0" class="floorplans-card">
            <mat-card-header>
                <mat-card-title>
                    <h2>Floorplans</h2>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="floorplans">
                    <ul>
                        <li *ngFor="let plan of plans | slice:0:community.display.floorplans.limit; let i = index" (click)="goto_floorplan(i)">
                            <div class="chips">
                                <mat-basic-chip *ngIf="plan.tour_url" class="has-tour" matTooltip="3D Tour Available" matTooltipPosition="right" (click)="goto_floorplan(i)">3D</mat-basic-chip>
                                <mat-basic-chip *ngIf="!plan.tour_url" class="no-tour" matTooltipPosition="right"></mat-basic-chip>
                            </div>
                            <div class="left">
                                <h3>{{plan.label}}</h3>
                                <p>{{plan.description}}</p>
                            </div>

                            <div class="right">
                                <h4 id="price" *ngIf="ui.hasPrice(community) && plan.price > 0">{{format_price(plan.price)}} +</h4>
                                <button mat-icon-button>
								<mat-icon class="layout-icon" color="green">chevron_right</mat-icon>
							</button>
                            </div>
                        </li>
                    </ul>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="links-card" *ngIf="community.display.commuity_links.active && (community.misc.addinfo || community.misc.calendar || community.files.events.length > 0  || community.files.menus.length > 0 || community.files.other.length > 0)">
            <mat-card-header>
                <mat-card-title>
                    <h2>Community Links</h2>
                </mat-card-title>
            </mat-card-header>
            <mat-list>
                <ng-container *ngIf="community.misc.website">
                    <mat-list-item *ngFor="let link of community.misc.website; let i = index">
                        <a [href]="link.value" target="_blank" (click)="community_link()">
                            <p>{{ link.label || 'Event'+(i>0? ' '+(i+1):'') }}</p>
                        </a>
                    </mat-list-item>
                </ng-container>
                <ng-container *ngIf="community.files.other.length > 0">
                    <mat-list-item *ngFor="let link of community.files.other; let i = index">
                        <a [href]="link.url" target="_blank" (click)="community_link()">
                            <p>{{ link.label || 'Additional Info'+(i>0? ' '+(i+1):'') }}</p>
                        </a>
                    </mat-list-item>
                </ng-container>
                <ng-container *ngIf="community.misc.calendar">
                    <mat-list-item *ngFor="let link of community.misc.calendar; let i = index">
                        <a [href]="link.value" target="_blank" (click)="community_link()">
                            <p>{{ link.label || 'Calendar'+(i>0? ' '+(i+1):'') }}</p>
                        </a>
                    </mat-list-item>
                </ng-container>
                <ng-container *ngIf="community.files.events.length > 0">
                    <mat-list-item *ngFor="let link of community.files.events; let i = index">
                        <a [href]="link.url" target="_blank" (click)="community_link()">
                            <p>{{ link.label || 'Event'+(i>0? ' '+(i+1):'') }}</p>
                        </a>
                    </mat-list-item>
                </ng-container>
                <ng-container *ngIf="community.files.menus.length > 0">
                    <mat-list-item *ngFor="let link of community.files.menus; let i = index">
                        <a [href]="link.url" target="_blank" (click)="community_link()">
                            <p>{{ link.label || 'Menu'+(i>0? ' '+(i+1):'') }}</p>
                        </a>
                    </mat-list-item>
                </ng-container>
            </mat-list>
        </mat-card>

        <mat-card class="care-card" *ngIf="community.display.care_options.active && community.care">
            <mat-card-header>
                <mat-card-title>
                    <h2>Housing & Care Options</h2>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ul>
                    <li *ngFor="let item of community.care">
                        {{item}}
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>

        <mat-card class="amenities-card" *ngIf="community.display.community_amenities.active && community.communityamenities">
            <mat-card-header>
                <mat-card-title>
                    <h2>Community Amenities</h2>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ul>
                    <li *ngFor="let item of community.communityamenities">
                        {{item}}
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>

        <mat-card class="amenities-card" *ngIf="community.display.suite_amenities.active && community.suiteamenities">
            <mat-card-header>
                <mat-card-title>
                    <h2>Suite Amenities</h2>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ul>
                    <li *ngFor="let item of community.suiteamenities">
                        {{item}}
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>

        <!--jason dump-->
        <mat-card style="display: none;">
            <mat-card-content>
                {{community | json}}
            </mat-card-content>
        </mat-card>
    </div>
</div>