import { of, throwError as observableThrowError, Observable } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { Bounds, community, stgOptions, LatLng } from '../definitions';

// Import RxJs required methods



declare var window: any;

@Injectable()
export class ApiService {
	api_uri: string;
	request: any;
	private headers = new HttpHeaders({
		"Access-Control-Allow-Methods": "GET, POST",
		'Content-Type': 'application/json'
	});
	// Cache variable to store responses
	private dataCache: { [key: string]: any } = {};
	constructor(
		private http: HttpClient,
		private s: SettingsService
	) {
		this.request = {};
		this.s.$settings.subscribe((data) => {
			this.init();
		});
	}
	init() {
		if (window.dev_mode) {
			this.api_uri = this.s.config.dev_api_endpoint;
		}
		else {
			this.api_uri = this.s.config.api_endpoint;
		}
	}
	post(uri, data) {
		if (!this.api_uri) { return new Observable(observer => { observer.complete(); }); }
		return this.http.post(this.api_uri + uri, data, { headers: this.headers }).pipe(retry(3));
	}
	get(uri: string): Observable<any> {
		// Check if the API URI is not set
		if (!this.api_uri) {
			return new Observable(observer => {
				observer.complete();
			});
		}

		// Check if data for the URI is cached
		if (this.dataCache[uri]) {
			return of(this.dataCache[uri]);
		}

		// If not cached, make the API call
		return this.http.get(uri, { headers: this.headers }).pipe(
			tap(data => {
				// Store the response in the cache
				this.dataCache[uri] = data;
			}),
			retry(3)
		);
	}
	basic_communities(mapbounds: Bounds, ids: number[]): Observable<any[]> {
		this.request.basic_communities = this.post("get_communities/", { bounds: mapbounds, ids: ids });
		// .map((res:Response) => res.json());

		return this.request.basic_communities;
	}

	get_community_info(ids: number[]): Observable<any> {
		this.request.get_community_info = this.post("get_community_info/", { ids: ids });
		return this.request.get_community_info;
	}

	geojson(ids: number[]): Observable<any> {
		this.request.geojson = this.post("get_geojson/", { locations: ids });
		// .map((res:Response) => res.json());

		return this.request.geojson;
	}

	getlatlng(address) {

		let api_key = (this.s.config && this.s.config.api_keys) ? this.s.config.api_keys.google_maps || '' : '';
		if (api_key != '') { api_key = '&key=' + api_key; }
		let url = 'https://maps.googleapis.com/maps/api/geocode/json'
			+ '?address=' + encodeURI(address)
			//+ '&components=administrative_area:MN,WI|country:US'
			+ api_key;
		return this.http.get(url).pipe(retry(3));
	}

	options(): Observable<stgOptions> {
		this.request.options = this.get(this.api_uri + "dev_get_options/").pipe(
			// .map((res:Response) => res.json())
			catchError((error: any) => observableThrowError(error.json().error || 'Server error')));
		return this.request.options;
	}
	community(id: number): Observable<community> {
		this.request.community = this.post("get_community/" + id, {}).pipe(
			// .map((res:Response) => res.json())
			catchError((error: any) => observableThrowError(error.json().error || 'Server error')));
		return this.request.community;
	}
	location_search(value: string): Observable<LatLng> {
		this.request.geolocation = this.post("search_location/", { search_term: value }).pipe(
			// .map((res:Response) => res.json())
			catchError((error: any) => observableThrowError(error || 'Server error')));
		return this.request.geolocation;
	}

	log(com_id: number, log_action: string): Observable<any> {
		let vars =
		{
			referrer: 'app',
			action: log_action,
			info: {
				community_id: com_id,
			}
		};
		this.request.log = this.post("log_data/", vars);
		return this.request.log;
	}

	// Method to clear cache
	clearCache(uri?: string): void {
		if (uri) {
			delete this.dataCache[uri];
		} else {
			this.dataCache = {};
		}
	}
}
