import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ApplicationRef, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { SliderModule } from './module/slider/slider.module';
import { LightboxModule, LightboxComponent } from './module/lightbox/lightbox.module';
import { TourModule, TourComponent } from './module/tour/tour.module';

import { ApiService } from './service/api.service';
import { AnalyticsService } from './service/analytics.service';
import { FilterService } from './service/filter.service';
import { MapService } from './service/map.service';
import { StorageService } from './service/storage.service';
import { SettingsService } from './service/settings.service';
import { AccessService } from './service/access.service';
import { UserdataService } from './service/userdata.service';
import { UiService } from './service/ui.service';

import { AngularMaterial } from './module/material';
import { RoutingModule } from './module/routing';

import { AppComponent } from './app.component';
import { FilterComponent } from './component/filter/filter.component';
import { SearchComponent } from './component/search/search.component';
import { CommunityComponent } from './component/community/community.component';
import { SettingsComponent } from './component/settings/settings.component';
import { AccessComponent } from './component/access/access.component';
import { AccountComponent } from './component/account/account.component';
import { SortComponent } from './component/sort/sort.component';
import { FloorplanComponent } from './component/floorplan/floorplan.component';
import { RemoteVideoComponent } from './component/remote-video/remote-video.component';
import { ContactComponent } from './component/contact/contact.component';
import { PhoneComponent } from './component/phone/phone.component';
import { RequestInfoComponent } from './component/request-info/request-info.component';
import { FavoritesComponent } from './component/favorites/favorites.component';

@NgModule({
	entryComponents: [
		AccessComponent,
		SortComponent,
		ContactComponent,
		PhoneComponent,
		RequestInfoComponent,
		LightboxComponent,
		TourComponent,
	],
	imports: [
		HttpClientModule,
		BrowserModule,
		CommonModule,
		BrowserAnimationsModule,
		AngularMaterial,
		FormsModule,
		ReactiveFormsModule,
		RoutingModule,
		HttpClientModule,
		MatDialogModule,
		SliderModule,
		LightboxModule,
		TourModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyBHd-pBl2cy72itxDrvPHAezmFwrcoJMko',
			libraries: ['places']
		}),
	],
	providers: [
		ApiService,
		AnalyticsService,
		StorageService,
		SettingsService,
		FilterService,
		MapService,
		GoogleMapsAPIWrapper,
		AccessService,
		UserdataService,
		UiService
	],
	declarations: [
		AppComponent,
		FilterComponent,
		SearchComponent,
		CommunityComponent,
		SettingsComponent,
		AccessComponent,
		AccountComponent,
		SortComponent,
		FloorplanComponent,
		RemoteVideoComponent,
		ContactComponent,
		PhoneComponent,
		RequestInfoComponent,
		FavoritesComponent,
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
