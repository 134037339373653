import { Component, OnInit } from '@angular/core';
import { AccessService } from '../../service/access.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  constructor(private access : AccessService) { }

  ngOnInit() {
	  this.access.get_token().then((auth) => {
		  //console.log('GET_TOKEN', auth);
	  }, console.error);
  }

  sign_out()
  {
	  this.access.sign_out();
  }

}
