import { Component, OnInit, AfterViewInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccessService } from '../../service/access.service';
import { FormControl, Validators } from '@angular/forms';

declare const gapi: any;

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss']
})
export class AccessComponent implements OnInit {

	@ViewChild('googleSignin') googleSignin: ElementRef;
    public auth2: any;
	public view: string = 'sign-in'; // sign-in, sign-up, spinner
	public errors: any = {};

	constructor(
	    public dialogRef: MatDialogRef<AccessComponent>,
	    @Inject(MAT_DIALOG_DATA) public data: any,
	    public access: AccessService
	) { }

	ngOnInit() {
	}

	ngAfterViewInit()
	{
		this.google_init(this.googleSignin.nativeElement, (user) => {
			this.access.google_signin(user).then((data) => {
				this.dialogRef.close();
			}, (err) => {
				console.error(err);
			});
		});
	}

	login_submit(email, password, confirm='')
	{
		this.view = 'spinner';
		this.access.login_provider(email, password, confirm).then((data) => {
			this.dialogRef.close();
		}, (err) => {
			switch (err.error)
			{
				case 'invalid_client':
				case 'passwords_differ':
					this.view = 'sign-up';
				break;

				case 'invalid_grant':
					// password is incorrect
					this.errors.password = err.error_description || "Password invalid.";
					this.view = 'sign-in';
				break;

				default:
					console.error(err.error, err.error_description);
					this.view = 'sign-in';
				break;
			}
		});

		// if (!email || !password)
		// {
		// 	console.error("Email and/or Password empty.");
		// 	return;
		// }
        //
	    // // this.loading = true;
		// this.view = 'spinner';
		// this.access.password_signin(email, password).then((data: any) => {
		// 	if (typeof data.access_token === 'undefined')
		// 	{
		// 		this.errors.signin = 'Server did not return access_token.';
		// 		return;
		// 	}
		// 	console.log(data);
		// 	this.dialogRef.close();
		// }, (err) => {
		// 	switch (err.error)
		// 	{
		// 		case 'invalid_client':
		// 			// user doesn't exist
		// 			this.view = 'sign-up';
		// 		break;
        //
		// 		case 'invalid_grant':
		// 			// password is incorrect
		// 			this.errors.password = err.error_description || "Password invalid.";
		// 			this.view = 'sign-in';
		// 		break;
        //
		// 		default:
		// 			console.error(err.error, err.error_description);
		// 			this.view = 'sign-in';
		// 		break;
		// 	}
		// });
	}

	google_init(container, login_callback)
	{
		gapi.load('auth2', () => {
			this.auth2 = gapi.auth2.init({
				client_id: '1089421159529-77mvtj979ng6id617sojfp9uj0iqh8ju.apps.googleusercontent.com',
				cookiepolicy: 'single_host_origin',
				scope: 'profile email',
			});
			this.auth2.attachClickHandler(container, {
				prompt: 'select_account'
			}, (data) => {
				login_callback(data);
			}, console.error);
		});
	}
}
