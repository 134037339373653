<div class="remote-video-wrapper" *ngIf="!hideThumb">
	<div class="thumbnail-wrapper" *ngIf="!video_shown; else Video">
		<img class="thumbnail-image" [src]="oembed.thumbnail_url || default_thumbnail" [alt]="oembed.title || default_title" (click)="show_video()" *ngIf="oembed"/>
		<mat-icon class="thumbnail-play">play_circle_filled</mat-icon>
	</div>
	<ng-template #Video>
		<div class="iframe_wrapper">
			<iframe class="video_iframe" [src]="video_url_sanatized" frameborder="0" [title]="" webkitallowfullscreen mozallowfullscreen allowfullscreen *ngIf="video_url"></iframe>
		</div>
	</ng-template>
</div>
